  // Config object to be passed to Msal on creation
  export const msalConfig = {
    auth: {
      clientId: "abf2df1d-3ea3-4ecb-a9af-8c59055146fa",
      authority: "https://login.microsoftonline.com/763c9d06-4adb-4bf3-940d-c86916f5a4e5",
      redirectURI: "https://localhost:5001/"
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
  };  
  
  // Add here scopes for id token to be used at MS Identity Platform endpoints.
  export const loginRequest = {
    scopes: ["api://6ca57540-876a-4223-8d49-ff3b985cc81f/access_as_user"],
  };

  //Config for indentity server
  export const ISConfig = {
    authority: "https://localhost:5001/",
    client_id: "MandantReportingClient",
    redirect_uri: "https://localhost:5001/",
    response_type: "code",
    scope:"openid profile wiendigitalazureapi",
    post_logout_redirect_uri : "https://localhost:5001/",
};