import React, { Component } from 'react';
import { Form, Input, Button, Select } from 'antd';

import { inject, observer } from 'mobx-react';
import { map } from 'lodash-es';
import { openNotificationWithIcon } from 'helpers/Notifications';
import CryptoJS from 'crypto-js';

@inject('userStore')
@observer
export class UserRegisterForm extends Component {
  state = {
    message: this.props.user? 'Geben Sie die neuen Daten für den Benutzer an.' : 'Geben Sie die Daten für den neuen User ein.',
    error: {},
    selected: undefined,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.getResponse(values);
      }
    });
  };

  async getResponse(values) {
    let encryptedPW;

    if (values.password) {
      var key = CryptoJS.enc.Utf8.parse('H,>v(27b2>4vz~JS');
      var iv = CryptoJS.enc.Utf8.parse('vv_8YqmSm6>8]s$$');
  
      var result = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(values.password), key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      encryptedPW = result.ciphertext.toString(CryptoJS.enc.Base64);
    }

    //If editing
    if (this.props.user) {
      var response = await this.props.userStore.updateUser(
        this.props.user.id,
        {id: this.props.user.id, displayName: values.name, upn: values.email, password: encryptedPW, role: values.role}
      );
    } else {  //If creating
      var response = await this.props.userStore.registerUser(
        values.name,
        values.email,
        encryptedPW
      );
    }

    //To display the response from the backend
    if (response.succeeded) {
      this.props.userStore.showRegisterModal = false;
      this.props.userStore.fetchUsers();
      openNotificationWithIcon(
        'success',
        this.props.user? 'Aktualisierung erfolgreich': 'Registierung erfolgreich',
        this.props.user? 'Der Benutzer wurde erfolgreich aktualisiert' : 'Der Benutzer wurde erfolgreich angelegt'
      );
    } else {
      var errObj = {};
      map(response.errors, (error) => {
        if (error.code.indexOf('Pass') > -1) {
          if (!errObj.password) {
            errObj['password'] = [];
          }
          errObj['password'].push(<li key={error.code}>{error.description}</li>);
        } else if (error.code.indexOf('User') > -1) {
          if (!errObj.username) {
            errObj['email'] = [];
          }
          errObj['email'].push(<li key={error.code}>{error.description}</li>);
        }
      });
      this.setState({ error: errObj });
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Die eingegebenen Passwörter stimmen nicht überein!');
    } else {
      callback();
    }
  };

  handleChange = (val) => {
    this.setState({ selected: val });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    let passError = this.state.error.password;
    let nameError = this.state.error.username;
    let emailError = this.state.error.email;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <h3>{this.state.message}</h3>
        <Form.Item
          hasFeedback={nameError}
          validateStatus={nameError ? 'error' : ''}
          help={nameError}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Bitte geben Sie den Namen ein!' }],
            initialValue: this.props.user?.displayName,
          })(<Input placeholder="Name"/>)}
        </Form.Item>

        <Form.Item
          hasFeedback={emailError}
          validateStatus={emailError ? 'error' : ''}
          help={emailError}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'Die Email-Adresse ist keine korrekte Email-Adresse!',
              },
              {
                required: true,
                message: 'Bitte geben Sie die Email-Adresse ein!',
              },
            ],
            initialValue: this.props.user?.upn,
          })(<Input placeholder="Email" />)}
        </Form.Item>

        <Form.Item
          hasFeedback={passError}
          validateStatus={passError ? 'error' : ''}
          help={passError}>
          {getFieldDecorator('password', {
            rules: [{ 
              required: this.props.user? false : true,
              message: 'Bitte geben Sie das Passwort ein!' 
            }],
          })(<Input.Password placeholder="Passwort" />)}
        </Form.Item>

        <Form.Item
          hasFeedback={passError}
          validateStatus={passError ? 'error' : ''}
          help={passError}>
          {getFieldDecorator('confirm', {
            rules: [
              {
                validator: this.compareToFirstPassword,
              },
              {
                required: this.props.user? false : true,
                message: 'Bitte geben Sie das Passwort erneut ein!',
              },
            ],
          })(<Input.Password placeholder="Passwort bestätigen" />)}
        </Form.Item>

        {this.props.user ? (
          <Form.Item>
          {getFieldDecorator('role', {
            rules: [{ required: this.props.user? false : true, message: 'Bitte geben Sie eine Rolle an!' }],
            initialValue: this.props.user?.role
          })(             
            <Select
              placeholder="Rolle"
              onChange={this.handleChange}
              style={{ width: '100%' }}>
              {map(this.props.roles, (x) => (
                <Select.Option key={x} value={x}>
                  {x}
                </Select.Option>
              ))}
            </Select>)}
          </Form.Item>
        ) : null}

        <Button className="register-btn" type="primary" htmlType="submit">
          {this.props.user? 'Speichern' : 'Registrieren'}
        </Button>
      </Form>
    );
  }
}

const LoginForm = Form.create({ name: 'login' })(UserRegisterForm);
export default LoginForm;
