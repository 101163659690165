import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Table, Icon, Divider, Card, Input, Checkbox, Modal, Tabs } from "antd";
import { Button, List, Tag } from "antd";
import { openNotificationWithIcon } from "helpers/Notifications";
import UserRegisterModal from "./UseRegisterModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MandantAdministartion from "./MandantAdministration";
import DeleteUserModal from "./DeleteUserModal";

const { TabPane } = Tabs;

@inject("userStore", "mandantStore")
@observer
export default class UserAdministration extends Component {
  state = {
    showDeleteUserModal: false,
    user: undefined,
  };

  routeTo(pathName) {
    this.props.history.push(pathName);
  }

  columns = [
    {
      title: "Name",
      key: "displayName",
      dataIndex: "displayName",
    },
    {
      title: "Email",
      key: "upn",
      dataIndex: "upn",
    },
    {
      title: "Rolle",
      key: "role",
      dataIndex: "role",
    },
    {
      title: "Aktion",
      dataIndex: "action",
      align: 'center',
      render: (text, record) => (
        <span>
          <Button
            onClick={() => this.registerUser(record)}
            className="table-action-btn"
          >
            <FontAwesomeIcon icon={["fa", "edit"]} />
          </Button>

          <span className="table-icon-seperator">|</span>

          <Button
            onClick={() => this.showDeleteUserModal(record)}
            className="table-action-btn-del"
          >
            {" "}
            <FontAwesomeIcon icon={["fa", "trash"]} />{" "}
        </Button>
      </span>
      ),
    },
  ];

  componentDidMount() {
    this.props.userStore.fetchUsers();
  }

  render() {
    let store = this.props.userStore;

    return (
      <React.Fragment>
        <h2>Benutzer-Administration</h2>
        <Card>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Benutzerübersicht" key="1">
              <Button
                onClick={() => this.registerUser()}
                className="float-right"
              >
                Neuen User anlegen
              </Button>

              <Table
                className="ant-table-margin"
                rowKey="id"
                columns={this.columns}
                dataSource={store.users.slice()}
                scroll={{ x: 850 }}
              />

              <UserRegisterModal
                visibility={this.props.userStore.showRegisterModal}
                handleCancel={this.handleCancel}
                user={this.state.user}
                roles={store.roles}
              />

              <DeleteUserModal
                visibility={this.state.showDeleteUserModal}
                handleCancel={this.handleCancel}
                handleOk={this.deleteUser}
                user={this.state.user}
              />
            </TabPane>
            <TabPane tab="Mandantenübersicht" key="2">
              <MandantAdministartion
                userStore={this.props.userStore}
                mandantStore={this.props.mandantStore}
              ></MandantAdministartion>
            </TabPane>
          </Tabs>
        </Card>
      </React.Fragment>
    );
  }

  showPasswordModal = (user) => {
    this.setState({ showPasswordModal: true, user: user});
  }

  showDeleteUserModal = (user) => {
    this.setState({ showDeleteUserModal: true, user: user});
  }

  deleteUser = async(user) => {
    this.props.userStore.deleteUser(user.id).then((resp) => {
      this.props.userStore.fetchUsers();
      openNotificationWithIcon(
        "success",
        "Löschen erfolgreich",
        "Der Benutzer wurde erfolgreich gelöscht."
      );
      this.props.mandantStore.fetchAllMandants();
      this.resetState();
    }).catch((err) => {
      openNotificationWithIcon(
        "error",
        "Fehler",
        "Der Benutzer konnte nicht gelöscht werden."
      );
    });
  }

  showPermissionModal = (user) => {
    this.setState({ showEditModal: true, user: user });
  };

  handleCancel = () => {
    this.resetState();
    this.props.userStore.showRegisterModal = false;
  };

  onSave = (id, value) => {
    this.props.userStore.updateUser(id, { id: id, role: value }).then((x) => {
      this.resetState();
      openNotificationWithIcon(
        "success",
        "Änderung erfolgreich",
        "Die Rechte wurden erfolgreich geändert"
      );
    });
  };

  resetState = () => {
    this.setState({ showEditModal: false, showDeleteUserModal: false, showPasswordModal: false, user: undefined });
  };

  registerUser = (user) => {
    this.setState({user: user}, () => {
      this.props.userStore.showRegisterModal = true;
    });
  };
}