import React, { Component } from 'react';
import { Layout, Menu, Tooltip } from 'antd';

import { withRouter } from 'react-router-dom';
import { map } from 'lodash-es';

import { sidebarStyle, sidebarWidth } from '../../globalVars';
import logo from '../../images/logo.png';

import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Sider } = Layout;
const { SubMenu } = Menu;

@inject('permissionStore', 'sidebarStore')
@observer
class Sidebar extends Component {
  state = {
    collapsed: false,
    selectedKeys: [this.props.history.location.pathname],
  };

  componentDidMount() {
    this.props.sidebarStore.fetchSidebarElements();
  }

  onHomeClick = () => {
    this.setState({ selectedKeys: [] });
    this.props.history.push(`/`);
  };

  onCollapse = (collapsed) => {
    this.props.permissionStore.isSidebarCollapsed = collapsed;
  };

  onMenuItemSelect = (params) => {
    let path = params.key;

    let id = path.split("/folderItem=")[0];
    this.props.history.push(id);
    this.setState({
      selectedKeys: [this.props.history.location.pathname],
    });
  };

  render() {
    let collapsed = this.props.permissionStore.isSidebarCollapsed;

    let menu =
      map(this.props.sidebarStore.elements, (item) => {
        return (
          <SubMenu
            key={item.id}
            title={
              <span className="span-report-menu">{item.name}</span>
            }>

              {map(item.reports, (x) => 
                <Menu.Item key={'/' + x.id + '/folderItem=' + item.id} style={this.state.selectedKeys[0]===('/'+x.id) ? {background: '#a6214c', color: '#fff'} : {}}>
                  <span className="span-report-menu">{x.displayName ? x.displayName : `Bericht ${x.id}`}</span>
                </Menu.Item>
              )};
          </SubMenu>
        );
      }) || [];

    return (
      <Sider
        width={275}
        breakpoint="xl"
        collapsible
        collapsedWidth="0"
        collapsed={collapsed}
        onCollapse={this.onCollapse}
        trigger={null}>
        <div className="logo">
          <img src={logo} alt="Fit4Internet Logo" onClick={this.onHomeClick} />
        </div>

        <Menu
          theme={sidebarStyle}
          className={collapsed ? 'menu-collapsed' : ''}
          selectedKeys={this.state.selectedKeys}
          mode="inline"
          onSelect={this.onMenuItemSelect}>
          {menu.length > 0 && (
            <Menu.Item key="/">
              <span className="span-report-menu">Home</span>
            </Menu.Item>
          )}
          {menu}
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(Sidebar);
