import React, { Component } from "react";

import { Redirect, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Route } from "react-router";

import Home from "components/Home/Home";
import Forbidden from "components/Forbidden/Forbidden";
import PowerBIAdministration from "components/Administration/PowerBIAdministration";
import UserAdministration from "components/Administration/UserAdministration";
import PowerBiPage from "components/PowerBi/PowerBiPage";

import AuthorizeRoute from "components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "components/utils/api-authorization/ApiAuthorizationConstants";
import { Logout } from "../api-authorization/Logout";

@inject("permissionStore")
@observer
class Routes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <AuthorizeRoute exact path="/" component={Home} />
          <Route exact path="/forbidden" component={Forbidden} />
          {this.props.permissionStore.isAdmin ? (
            <AuthorizeRoute
              exact
              path="/useradministration"
              component={UserAdministration}
            />
          ) : (
            <Redirect exact path="/useradministration" to="/" />
          )}
          {this.props.permissionStore.isAdmin ? (
            <AuthorizeRoute
              exact
              path="/powerbiadministration"
              component={PowerBIAdministration}
            />
          ) : (
            <Redirect exact path="/powerbiadministration" to="/" />
          )}
          <AuthorizeRoute exact path="/Logout/:action" component={Logout} />
          <AuthorizeRoute exact path="/:id" component={PowerBiPage} />
          <Route path="/lib"></Route>
          <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          ></Route>
        </Switch>
      </div>
    );
  }
}

export default Routes;
