import { observable, computed } from 'mobx';
import axios from 'axios';

import { isProduction, apiUrl } from '../globals';

class PermissionStore {
  @observable
  accessToken = undefined;

  @observable
  user = { givenName: 'No', surName: 'User', completeRoleString: '', role: [] };

  @observable
  isSidebarCollapsed = false;

  history = undefined;

  constructor() {
    if (!isProduction) {
      console.log('PermissionStore created');
      window['PermissionStore'] = this;
    }
  }

  @computed get isAdmin() {
    if (this.user.role && this.user.role?.includes('Admin')) {
      return true;
    } else {
      return false;
    }
  }

  @computed get isApprover() {
    if (this.user.role && this.user.role?.includes('APPROVER')) {
      return true;
    } else {
      return false;
    }
  }

  async fetchUser() {
    var resp = await axios.get(`${apiUrl}/permission/getuser`);
    return (this.user = resp.data);
  }

  redirectToForbidden = () => {
    if (this.history) {
      this.history.push('forbidden');
    }
  };
}

export default new PermissionStore();
