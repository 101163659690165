import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faPlus,
  faEdit,
  faMinus,
  faUser,
  faCircle,
  faGlobe,
  faUserCog,
  faUsersCog,
  faCaretDown,
  faCaretLeft,
  faAlignJustify,
  faSignOutAlt,
  faCog,
  faFileMinus,
  faUniversity,
  faTrashAlt,
  faCaretRight,
  faChevronDown,
  faEye,
  faTrash,
  faFolder,
  faSortUp,
  faSortDown,
  faTimes,
  faLongArrowAltLeft,
  faUnlock,
  faKey,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faBars,
  faUser as faUserRegular
} from '@fortawesome/pro-regular-svg-icons';

import {} from "@fortawesome/pro-light-svg-icons";
//Add all solid icons
// library.add(solid)

//Add your icons here from https://fontawesome.com/icons?d=gallery&s=solid
library.add(
  faHome,
  faPlus,
  faEdit,
  faMinus,
  faUser,
  faCircle,
  faGlobe,
  faUserCog,
  faUsersCog,
  faCaretDown,
  faCaretLeft,
  faAlignJustify,
  faSignOutAlt,
  faCog,
  faFileMinus,
  faUniversity,
  faTrashAlt,
  faCaretRight,
  faBars,
  faChevronDown,
  faEye,
  faUserRegular,
  faTrash,
  faFolder,
  faSortUp,
  faSortDown,
  faTimes,
  faLongArrowAltLeft,
  faUnlock,
  faKey,
);

// See https://fontawesome.com/how-to-use/use-with-node-js#pro
// Be sure to use the pro icons! https://fontawesome.com/how-to-use/js-component-packages
// Custom icons (regular or light ones) can be added too

dom.watch();
