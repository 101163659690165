import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Col, Row, Checkbox, Input, Select } from "antd";
import { map, indexOf } from "lodash-es";
import { observable } from "mobx";

@inject("mandantStore", "userStore")
@observer
export default class UsersInMandantModal extends Component {
  state = {
    userIds: [],
    updateFlag: true,
  };

  componentDidUpdate = () => {
    if (this.props.visible && this.props.mandantStore.modalOpen) {
      let mandant = this.props.mandantStore.mandant
        ? this.props.mandantStore.mandant
        : {};
      let usersInMandant = mandant.userInMandant?.slice() || [];
      let userIds = [];

      if (usersInMandant.length > 0) {
        userIds = map(usersInMandant, (x) => x.id);
      }

      this.setState({ userIds: userIds });
      this.setState({ updateFlag: false });
      this.props.mandantStore.modalOpen = false;
    }
  };

  handleOk = () => {
    this.props.handleOk(this.props.mandantStore.mandant, this.state.userIds);
  };

  handleChange = (value) => {
    this.setState({ userIds: value });
  };

  render() {
    let mandant = this.props.mandantStore.mandant
      ? this.props.mandantStore.mandant
      : {};
    let usersInMandant = mandant.userInMandant?.slice() || [];

    let userIds = [];

    if (usersInMandant.length > 0) {
      userIds = map(usersInMandant, (x) => x.id);
    }

    let users = this.props.userStore.remainingUsers;

    return (
      <Modal
        onCancel={this.props.handleCancel}
        onOk={this.handleOk}
        visible={this.props.visible}
        title="Users in Mandant verwalten"
        okText={"Speichern"}
        cancelText={"Abbrechen"}
        cancelButtonProps={{ className: "white" }}
        destroyOnClose
      >
        <Row className="modal-input-row">
          <div className="label">Benutzer hinzufügen</div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="User auswählen"
            defaultValue={userIds}
            onChange={this.handleChange}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {map(users, (x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.upn || x.displayName}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </Modal>
    );
  }
}
