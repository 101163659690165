import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, Input, Row, Select, Button, Form } from 'antd';
import { map, indexOf } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FormItem = Form.Item;

@inject('powerBiReportStore', 'userStore')
@observer
export default class RoleForReportModal extends Component {
    onFormChange = e => {
        this.setState({ error: false, invalidCreds: false });
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    handleOk = () => {
        this.props.handleOk();
    };

    render() {
        return (
            <WrappedForm
                handleOk={this.handleOk}
                handleCancel={this.handleCancel}
                visible={this.props.visible}
                onChange={this.onFormChange}
                powerBiReportStore={this.props.powerBiReportStore}/>
        );
    }
}


@observer
class RoleForReportForm extends React.Component {
    state= {
        errMsg: "",
    }

    addRoles = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let roleObj = {id: undefined, powerBiReportId: this.props.powerBiReportStore.report.id, name: values.role};

                if (this.props.powerBiReportStore.report.roles.length > 0) {
                    let flag = false;

                    map(this.props.powerBiReportStore.report.roles, (x) => {
                        x.name == values.role ? flag = true : null;
                    });

                    if (!flag) {
                        if (values.role != undefined) {
                            if (values.role.length > 0) {
                                this.props.powerBiReportStore.report.roles.push(roleObj)
                                this.setState({errMsg: ""});
                            } else {
                                this.setState({errMsg: "Bitte geben Sie zuerst eine Rolle an!"});
                            }
                        }
                    } else {
                        this.setState({errMsg: "Diese Rolle wurde bereits zu diesem Bericht hinzugefügt!"});
                    }
                } else {
                    if (values.role != undefined) {
                        if (values.role.length > 0) {
                            this.props.powerBiReportStore.report.roles.push(roleObj);
                            this.setState({errMsg: ""});
                        } else {
                            this.setState({errMsg: "Bitte geben Sie zuerst eine Rolle an!"});
                        }
                    } else {
                        this.setState({errMsg: "Bitte geben Sie zuerst eine Rolle an!"});
                    }
                }
            }
        });
    }

    deleteRole = (role) => {
        this.props.powerBiReportStore.report.roles.splice(this.props.powerBiReportStore.report.roles.indexOf(role), 1);
    }

    handleOk = () => {
        this.props.handleOk();
    }

    handleCancel = () => {
        this.setState({errMsg: ""});
        this.props.handleCancel();
    }

    render() {
        let report = this.props.powerBiReportStore.report
        ? this.props.powerBiReportStore.report
        : {};

        let roles = report.roles?.slice() || [];
        let roleNames = [];

        if (roles.length > 0) {
            roleNames = map(roles, (x) => <li key={x.name}>{x.name} <Button style={{position: "block-inline"}} className="table-action-btn-folder" onClick={() => this.deleteRole(x)}><FontAwesomeIcon icon={['fa', 'times']}/></Button></li>);
        }

        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                okText={"Speichern"}
                cancelText={"Abbrechen"}
                cancelButtonProps={{className: "white"}}
                visible={this.props.visible}
                title="Rollen für Bericht bearbeiten"
                powerBiReportStore={this.props.powerBiReportStore}
                destroyOnClose>
                
                <Form>
                    {this.state.errMsg.length > 0 ? 
                        <Row style={{color:"#ff5865"}}>{this.state.errMsg}</Row> : null}

                    <FormItem label="Rolle eingeben">
                        {getFieldDecorator("role")(
                            <Input onChange={this.handleChange}></Input>
                        )}
                    </FormItem>

                    <FormItem>
                        <Button onClick={this.addRoles}>Rolle hinzufügen</Button>
                    </FormItem>

                    {roleNames.length > 0 ? (
                        <FormItem label="Rollen">
                            <ul>{roleNames}</ul>
                        </FormItem>
                    ) : null}
                </Form>
            </Modal>
        );
    }
}

const WrappedForm = Form.create({
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    }
})(RoleForReportForm);