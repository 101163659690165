import { observable, computed } from 'mobx';
import axios from 'axios';

import { isProduction, apiUrl } from '../globals';

const url = `${apiUrl}/authentication`;

class AuthenticationStore {
  async login(username, password) {
    var returnUrl = window.location.search;

    console.log(`${apiUrl}/authentication`);
    var resp = await axios.post(`${apiUrl}/authentication`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: {
          Username: username,
          Password: password,
          ReturnUrl: returnUrl
        }
      });
    return resp.data;
  }
}

export default new AuthenticationStore();
