export const sidebarWidth = 350;
export const sidebarStyle = "light";

const mediaBreakpointValues = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};
//Names for Breakpoints
export const mediaBreakpoints = {
  sm: mediaBreakpointValues.sm,
  md: mediaBreakpointValues.md,
  lg: mediaBreakpointValues.lg,
  xl: mediaBreakpointValues.xl,
  xxl: mediaBreakpointValues.xxl,

  phone: mediaBreakpointValues.sm,
  tablet: mediaBreakpointValues.md,
  tabletv: mediaBreakpointValues.md,
  tableth: mediaBreakpointValues.lg,
  desktop: mediaBreakpointValues.xl,
  large: mediaBreakpointValues.xxl
};
