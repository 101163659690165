import axios from "axios";
import mobx, { observable } from "mobx";
import { isProduction, apiUrl } from "../globals";

const baseUrl = `${apiUrl}/powerbi`;

class PowerBiStore {
  @observable
  powerBiConfig = {};

  @observable
  loading = false;

  @observable
  reports = [];
  
  constructor() {
    if (!isProduction) {
      console.log("PowerBiStore created");
      window[`PowerBiStore`] = this;
    }
  }

  async fetchReportConfig(id) {
    return await axios.get(`${baseUrl}/getReport?id=${id}`)
      .then(resp => {
        this.powerBiConfig = resp.data;
        return this.powerBiConfig;
      });
  }

  async fetchReportForRole(role) {
    axios
      .get(`${baseUrl}`, {
        params: {
          username: "embedded@reportingplattform.gv.at",
          roles: role
        }
      })
      .then(resp => {
        this.powerBiConfig = resp.data;
      });
  }

  async deleteReportFromCloud(id) {
    return await axios.get(`${baseUrl}/deletereport/${id}`);
  }

  async importReportsFromCloud(id) {
    return await axios.get(`${baseUrl}/importReports`);
  }

  async exportReportToPdf(id) {
    return await axios(`${baseUrl}/exportpdf/${id}`, {
      method: 'GET',
      responseType: 'blob' //Force to receive data in a Blob Format
    });
  }
}

export default new PowerBiStore();
