import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS, observable } from 'mobx';
import { Spin, Row, Modal, notification, Button } from 'antd';
import { saveAs } from 'file-saver';
import * as pbi from 'powerbi-client';
import { debounce } from 'lodash-es';

import {
  openNotificationWithIcon,
  openFailureNotificationWithIcon,
  openSuccessNotificationWithIcon,
} from 'helpers/Notifications';

@inject('powerBiStore', 'filterStore', 'sidebarStore')
@observer
export default class PowerBiPage extends Component {
  state = {
    report: null,
    showDashboard: false,
    reportLoaded: false,
    reportName: null,
    checkedLayout: false,
  };

  @observable
  loadingPdf = false;

  componentDidMount() {
    this.mounted = true;
    let id = this.props.match.params.id;
    this.props.powerBiStore.loading = true;
    this.fetchReport(id);
    this.id = id;
    window.addEventListener('resize', debounce(this.checkLayout, 500));
  }

  componentDidUpdate(nextProps) {
    let id = this.props.match.params.id;

    var element = this.props.sidebarStore.elements.find((item) => item.id === id);

    if (element !== undefined && element.displayName !== this.state.reportName) {
      this.setState({ reportName: element.displayName });
    }

    if (nextProps.match.params.id != id) {
      this.id = id;
      this.props.powerBiStore.loading = true;
      this.setState({ report: null, checkedLayout: false });
      this.fetchReport(id);
    }
  }

  componentWillUnmount = () => {
    this.mounted = false;
    window.removeEventListener('resize', this.checkLayout);
  };

  checkLayout = () => {
    try {
      if (!this.props.powerBiStore.loading && window.innerWidth < 576) {
        var embedContainer = this.refs['reportContainer'];
        let report = powerbi?.get(embedContainer);
        let config = this.state.report.config;
        var that = this;

        report?.getPages().then(function (pages) {
          pages[0].hasLayout(pbi.models.LayoutType.MobilePortrait).then(function (hasLayout) {
            if (hasLayout && config.settings.layoutType !== pbi.models.LayoutType.MobilePortrait) {
              config.settings = {
                layoutType: pbi.models.LayoutType.MobilePortrait,
              };

              powerbi.reset(embedContainer)

              if (that.state.report.config.type == 'dashboard') {
                that.setState({
                  report: powerbi?.embed(that.refs.dashboardContainer, config),
                  showDashboard: true,
                  checkedLayout: true,
                });
              } else {
                that.setState({
                  report: powerbi?.embed(that.refs.reportContainer, config),
                  showDashboard: false,
                  checkedLayout: true,
                });
              }
            } else {
              openNotificationWithIcon(
                'warning',
                'Achtung!',
                'Für diesen Report ist keine mobile Darstellung vorhanden!'
              );
            }
          });
        });
      }
      if (!this.props.powerBiStore.loading && window.innerWidth > 576) {
        var embedContainer = this.refs['reportContainer'];
        let report = powerbi?.get(embedContainer);
        let config = this.state.report.config;
        var that = this;

        report?.getPages().then(function (pages) {
          pages[0].hasLayout(pbi.models.LayoutType.Master).then(function (hasLayout) {
            if (hasLayout && config.settings.layoutType !== pbi.models.LayoutType.Master) {
              config.settings = {
                layoutType: pbi.models.LayoutType.Master,
              };

              powerbi.reset(embedContainer)

              if (that.state.report.config.type == 'dashboard') {
                that.setState({
                  report: powerbi?.embed(that.refs.dashboardContainer, config),
                  showDashboard: true,
                  checkedLayout: true,
                });
              } else {
                that.setState({
                  report: powerbi?.embed(that.refs.reportContainer, config),
                  showDashboard: false,
                  checkedLayout: true,
                });
              }
            } 
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  saveFilters = async () => {
    let report = this.state.report;
    let me = this;

    //This is a fucked up code
    //I'm sorry not sorry
    let pages = await report.getPages();
    let outerResponse = new Promise((resolveOuter, reject) => {
      pages.forEach(async (page, index) => {
        let pagesFilters = await page.getFilters();
        pagesFilters = pagesFilters.filter(
          (item) => item.values?.length > 0 || item.conditions?.length > 0
        );

        pagesFilters.forEach((pageFilter) => {
          let pageFilterToSave = {
            powerBiReportId: me.id,
            pageName: page.name,
            visualName: '',
            value: JSON.stringify(pageFilter),
            level: 'page',
          };
          //Add Pages Filters
          this.props.filterStore.filtersToSave.push(pageFilterToSave);
        });

        let visuals = await page.getVisuals();
        let innerResponse = new Promise((resolveInner, reject) => {
          visuals.forEach(async (visual, index) => {
            let visualFilters = await visual.getFilters();
            if (visualFilters.length > 0) {
              visualFilters = visualFilters.filter(
                (item) => item.values.length > 0 || item.conditions?.length > 0
              );
              visualFilters.forEach((visualFilter) => {
                let visualFilterToSave = {
                  powerBiReportId: me.id,
                  pageName: page.name,
                  visualName: visual.name,
                  value: JSON.stringify(visualFilter),
                  level: 'visual',
                };
                //Add Visual Filters
                this.props.filterStore.filtersToSave.push(visualFilterToSave);
              });
            }
            //If last visual of page is reached, resolve inner promise
            if (index === visuals.length - 1) {
              resolveInner();
            }
          });
        });

        innerResponse.then((res) => {
          //If lastpage is reached, resolve outer promise
          if (index === pages.length - 1) {
            resolveOuter();
          }
        });
      });
    });

    outerResponse.then((res) => {
      this.props.filterStore
        .saveFilters(this.id, this.props.filterStore.filtersToSave)
        .then((resp) => {
          openSuccessNotificationWithIcon('Filter erfolgreich gespeichert!');
          this.props.filterStore.filtersToSave = [];
        })
        .catch((err) => {
          openFailureNotificationWithIcon('Filter konnten nicht gespeichert werden!');
        });
    });
  };

  printPage = () => {
    let me = this;

    Modal.warn({
      title: 'Drucken',
      content:
        'Achtung, durch die unterschiedlichen Auflösungen kann es möglich sein, dass die Druckansicht verändert wird. Wenn der Bericht nicht komplett sichtbar ist, erhöhen Sie den Zoomfaktor Ihres Browsers.',
      okText: 'Verstanden',
      className: 'offset-modal',
      onOk() {
        let report = me.state.report;

        report.print();
      },
      onCancel() {},
    });
  };

  pdfExport = async () => {
    this.loadingPdf = true;

    var key = `open${Date.now()}`;
    var btn = <Spin spinning={this.loadingPdf}></Spin>;

    notification.open({
      message: 'PDF Export',
      description: 'Der Export des Berichtes wurde gestartet.',
      btn,
      key,
      duration: 0,
    });

    this.props.powerBiStore
      .exportReportToPdf(this.state.report.config.id)
      .then((response) => {
        this.loadingPdf = false;

        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/pdf' });

        btn = (
          <Button
            onClick={() => {
              saveAs(file);
              notification.close(key);
            }}>
            Datei anzeigen
          </Button>
        );
        notification.close(key);

        key = `open${Date.now()}`;
        notification.success({
          message: 'Export erfolgreich',
          description:
            'Der Bericht wurde erfolgreich zu PDF exportiert. Drücken Sie auf den Button, um die Datei in einem neuen Fenster zu öffnen.',
          btn,
          key,
        });
      })
      .catch(() => {
        this.loadingPdf = false;
        notification.close(key);
        Modal.error({
          title: 'Fehler',
          content: 'Es ist ein Fehler beim Exportieren des Berichts aufgetreten.',
        });
      });
  };

  clearFilter = () => {
    this.props.filterStore
      .deleteFilters(this.id)
      .then((resp) => {
        openSuccessNotificationWithIcon('Filter erfolgreich zurückgesetzt!');
        this.props.filterStore.filtersToSave = [];
        this.props.filterStore.filters = [];
        let report = this.state.report;
        report.reload();
      })
      .catch((err) => {
        openFailureNotificationWithIcon('Filter konnten nicht zurückgesetzt werden!');
      });
  };

  setFilters = async () => {
    if (this.state.reportLoaded) return;
    let report = this.state.report;
    if (!report) {
      return;
    }

    let pages = await report.getPages();

    let filters = this.props.filterStore.filters;

    pages.forEach(async (page, index) => {
      let pagefilters = filters.filter(
        (item) => item.pageName == page.name && item.level == 'page'
      );

      if (pagefilters.length > 0) {
        let pageFilterValues = pagefilters.map((item) => JSON.parse(item.value));
        let resp = await page.setFilters(pageFilterValues);
      }
      let visuals = await page.getVisuals();

      visuals.forEach(async (visual, index) => {
        let visualFilters = filters.filter(
          (item) =>
            item.pageName == page.name && item.visualName == visual.name && item.level == 'visual'
        );

        if (visualFilters.length > 0) {
          let visualFilterValues = visualFilters.map((item) => JSON.parse(item.value));
          let resp = await visual.setFilters(visualFilterValues);
        }
      });
    });

    this.setState({ reportLoaded: true });
  };

  fetchReport = (id) => {
    this.setState({ reportLoaded: false });
    this.props.filterStore.getFilters(id);
    this.props.powerBiStore.fetchReportConfig(id).then((x) => {
      let config = {
        type: x.type,
        tokenType: 1,
        accessToken: x.embedToken.token,
        embedUrl: x.embedUrl,
        id: x.id,
        settings: {
          layoutType:
            window.innerWidth < 576
              ? pbi.models.LayoutType.MobilePortrait
              : pbi.models.LayoutType.Master,
              filterPaneEnabled: false,
        },
      };

      this.props.powerBiStore.loading = false;
      if (this.mounted) {
        if (x.type == 'dashboard') {
          this.setState(
            {
              report: powerbi.embed(this.refs.dashboardContainer, config),
              showDashboard: true,
            },
            () => this.state.report?.on('loaded', this.onReportLoaded)
          );
        } else {
          this.setState(
            {
              report: powerbi?.embed(this.refs.reportContainer, config),
              showDashboard: false,
            },
            () => this.state.report?.on('loaded', this.onReportLoaded)
          );
        }
      }
    });
  };

  onReportLoaded = () => {
    if (!this.state.checkedLayout) this.checkLayout();
    this.setFilters();
    this.setState({ reportLoaded: true });
  };

  render() {
    let loading = this.props.powerBiStore.loading;

    let reportVisible = !loading && !this.state.showDashboard;
    let dashboardVisible = !loading && this.state.showDashboard;

    return (
      <div>
        <Spin spinning={loading} tip="Verbindung zu PowerBI wird hergestellt..." size="large">
          <Row>
            <h2 className="report-name">{this.state.reportName}</h2>
            <div className="powerbi-options">
              <Button
                className="option-button yellow"
                disabled={!this.state.reportLoaded}
                onClick={this.printPage}>
                Drucken
              </Button>
            </div>
          </Row>
          <Row className="report-row">
            <div
              ref="reportContainer"
              className={'report-container' + (reportVisible ? '' : ' display-none')}>
              <div>
                <h3>Verbindung zu PowerBi wird hergestellt...</h3>
              </div>
            </div>

            <div
              ref="dashboardContainer"
              id="dashboardContainer"
              className={'report-container' + (dashboardVisible ? '' : ' display-none')}>
              <div>
                <h3>Verbindung zu PowerBi wird hergestellt...</h3>
              </div>
            </div>
          </Row>
        </Spin>
      </div>
    );
  }
}
