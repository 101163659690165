import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Col, Row, Checkbox, Input, Select } from "antd";
import { map, indexOf } from "lodash-es";
import { observable } from "mobx";

@inject("powerBiReportStore", "userStore", "folderItemStore", "mandantStore")
@observer
export default class PowerBIReportPermissionModal extends Component {
  state = {
    userIds: [],
    mandantIds: [],
  };

  componentDidUpdate = () => {
    if (this.props.visible && this.props.folderItemStore.modalOpen) {
      let folderItem = this.props.folderItemStore.folderItem
        ? this.props.folderItemStore.folderItem
        : {};
      let permissions = folderItem.permissions?.slice() || [];

      let userIds = [];

      if (permissions.length > 0) {
        userIds = map(permissions, (x) => x.userId);
      }

      let mandantsInFolder =
        this.props.mandantStore.mandantsInFolder?.slice() || [];
      let mandantIds = [];

      if (mandantsInFolder.length > 0) {
        mandantIds = map(mandantsInFolder, (x) => x.id);
      }

      this.setState({ userIds: userIds });
      this.setState({ mandantIds: mandantIds });
      this.props.folderItemStore.modalOpen = false;
    }
  };

  onCheckBoxChange = (e, key) => {
    this.props.folderItemStore.folderItem[key] = !this.props.folderItemStore
      .folderItem[key];
  };

  handleOk = () => {
    this.props.handleOk(
      this.props.folderItemStore.folderItem,
      this.state.userIds,
      this.state.mandantIds
    );
  };

  handleChange = (value) => {
    this.setState({ userIds: value });
  };

  handleChangeMandantSelect = (value) => {
    this.setState({ mandantIds: value });
  };

  render() {
    let folderItem = this.props.folderItemStore.folderItem
      ? this.props.folderItemStore.folderItem
      : {};
    let permissions = folderItem.permissions?.slice() || [];

    let userIds = [];

    if (permissions.length > 0) {
      userIds = map(permissions, (x) => x.userId);
    }

    let mandantsInFolder =
      this.props.mandantStore.mandantsInFolder?.slice() || [];
    let mandantIds = [];

    if (mandantsInFolder.length > 0) {
      mandantIds = map(mandantsInFolder, (x) => x.id);
    }

    let mandantStore = this.props.mandantStore ? this.props.mandantStore : {};
    let mandants = mandantStore.mandants?.slice() || [];

    return (
      <Modal
        onCancel={this.props.handleCancel}
        onOk={this.handleOk}
        visible={this.props.visible}
        title="Berechtigungen verwalten"
        okText={"Speichern"}
        cancelText={"Abbrechen"}
        cancelButtonProps={{ className: "white" }}
        destroyOnClose
      >
        {/* <Row className="modal-input-row">
          <div className="label">Benutzeremail hinzufügen</div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="User auswählen"
            defaultValue={userIds}
            onChange={this.handleChange}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {map(this.props.users, (x) => (
              <Select.Option
                key={x.id}
                value={x.id}
                disabled={folderItem?.showEveryone}
              >
                {x.upn || x.displayName}
              </Select.Option>
            ))}
          </Select>
        </Row> */}

        <Row className="modal-input-row">
          <div className="label">Mandanten hinzufügen</div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Mandanten auswählen"
            onChange={this.handleChangeMandantSelect}
            defaultValue={mandantIds}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {map(mandants, (x) => (
              <Select.Option
                key={x.id}
                value={x.id}
                disabled={folderItem?.showEveryone}
              >
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Row>

        <Row className="modal-input-row">
          <div className="label">Sichtbarkeit</div>
          <Checkbox
            checked={folderItem?.showEveryone}
            onChange={(e) => this.onCheckBoxChange(e, "showEveryone")}
          >
            für alle sichtbar
          </Checkbox>
        </Row>
      </Modal>
    );
  }
}
