import './globalImports';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { spy } from 'mobx';
import { initAuthentication } from 'components/utils/api-authorization/AuthorizeService';

import App from 'components/App/App';
import * as serviceWorker from './serviceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

initAuthentication();

// This will spy on mobx actions and log them to dev tools, use this code in dev only!
spy((event) => {
  if (event.type === 'action') {
    console.log(`${event.name} with args: ${event.arguments}`);
  }
});

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
      <App/>
  </BrowserRouter>,
  rootElement
);
/*
authUserAzureAD().then((x) => {
  ReactDOM.render(renderWithAuth(x), rootElement);
});

function renderWithAuth(token) {
  return (
    <BrowserRouter basename={baseUrl}>
      <App token={token} />
    </BrowserRouter>
  );
}
*/

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.

serviceWorker.unregister();