import {notification
} from "antd";

export function openNotificationWithIcon  (type, message, description)  {
   return notification[type]({
      message: message,
      description: description,
      duration: 2.5
    });
  };

export function openSuccessNotificationWithIcon  (description)  {
   return notification['success']({
      message: "Erfolgreich!",
      description: description,
      duration: 2.5
    });
  };

export function openFailureNotificationWithIcon  (description)  {
   return notification['error']({
      message: "Fehler!",
      description: description,
      duration: 2.5
    });
  };