import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Table,
  Icon,
  Divider,
  Card,
  Input,
  Checkbox,
  Modal,
  Anchor,
} from 'antd';
import { Button, Tabs, Row } from 'antd';
import { toJS } from 'mobx';
import { openNotificationWithIcon } from 'helpers/Notifications';
import PowerBIReportPermissionModal from './PowerBIReportPermissionModal';
import AddFolderModal from './AddFolderModal';
import FolderPermissionModal from './FolderPermissionModal';
import AddReportModal from './AddReportModal';
import ReportSummary from './ReportSummary';
import DeleteFolderModal from './DeleteFolderModal';
import DeleteFolderItemModal from './DeleteFolderItemModal';

import { map } from 'lodash-es';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { TabPane } = Tabs;

@inject(
  'powerBiReportStore',
  'powerBiStore',
  'sidebarStore',
  'userStore',
  'folderStore',
  'folderItemStore',
  'mandantStore'
)
@observer
export default class PowerBIAdministration extends Component {
  state = {
    showPermissionModalForReport: false,
    showPermissionModalForFolder: false,
    showAddFolderModal: false,
    showReportForFolder: false,
    showAddReportModal: false,
    showDeleteFolderModal: false,
    showDeleteFolderItemModal: false,
  };

  folderColumns = [
    {
      title: 'Typ',
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      render: (text, record) => (
        <Button
          name="showReports"
          onClick={(event) => this.showReportForFolder(record, event)}
          className="table-action-btn-folder"
        >
          <FontAwesomeIcon icon={['fa', 'folder']} />
        </Button>
      ),
    },
    {
      title: 'Reihenfolge',
      key: 'reihenfolge',
      dataIndex: 'reihenfolge',
      align: 'center',
      render: (text, record, index) => (
        <span>
          {this.props.folderStore.folders.length > 1 && index == 0 ? (
            <Button
              onClick={(event) => this.orderFoldersDown(record, event)}
              className="table-action-btn-sort"
            >
              <FontAwesomeIcon icon={['fa', 'sort-down']} />
            </Button>
          ) : null}

          {this.props.folderStore.folders.length > 1 &&
          index == this.props.folderStore.folders.length - 1 ? (
            <Button
              onClick={(event) => this.orderFoldersUp(record, event)}
              className="table-action-btn-sort"
            >
              <FontAwesomeIcon icon={['fa', 'sort-up']} />
            </Button>
          ) : null}

          {this.props.folderStore.folders.length > 1 &&
          index != 0 &&
          index != this.props.folderStore.folders.length - 1 ? (
            <span className="sorters-btn-wrapper">
              <Button
                onClick={(event) => this.orderFoldersUp(record, event)}
                className="table-action-btn-sort"
              >
                <FontAwesomeIcon icon={['fa', 'sort-up']} />
              </Button>
              <Button
                onClick={(event) => this.orderFoldersDown(record, event)}
                className="table-action-btn-sort"
              >
                <FontAwesomeIcon icon={['fa', 'sort-down']} />
              </Button>{' '}
            </span>
          ) : null}
        </span>
      ),
    },
    {
      title: 'Anzeige-Name',
      dataIndex: 'name',
      width: '50%',
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          onChange={(e) => this.onInputChange(e, 'name', record)}
          onBlur={() => this.onSaveFolder(record)}
          onClick={(event) => this.onClickFolderName(event)}
          onPressEnter={() => this.onSaveFolder(record)}
        ></Input>
      ),
    },
    {
      title: 'Für Alle Sichtbar',
      dataIndex: 'showEveryone',
      align: 'center',
      render: (text) =>
        text == true ? <Icon type="check" /> : <Icon type="close" />,
    },
    {
      title: 'Aktion',
      dataIndex: 'action',
      className: 'table-header-action',
      render: (text, record) => (
        <span>
          <Button
            onClick={(event) =>
              this.showPermissionModalForFolder(record, event)
            }
            className="table-action-btn"
          >
            <FontAwesomeIcon icon={['fa', 'eye']} />
          </Button>
          <span className="table-icon-seperator">|</span>
          <Button
            onClick={(event) => this.showDeleteFolderModal(record, event)}
            className="table-action-btn-del"
          >
            <FontAwesomeIcon icon={['fa', 'trash']} />
          </Button>
        </span>
      ),
    },
  ];

  reportInFolderColumns = [
    {
      title: 'Reihenfolge',
      key: 'reihenfolge',
      dataIndex: 'reihenfolge',
      render: (text, record, index) => (
        <span>
          {this.props.folderItemStore.folderItems.length > 1 && index == 0 ? (
            <Button
              onClick={() => this.orderReportsDown(record)}
              className="table-action-btn-sort"
            >
              <FontAwesomeIcon icon={['fa', 'sort-down']} />
            </Button>
          ) : null}

          {this.props.folderItemStore.folderItems.length > 1 &&
          index == this.props.folderItemStore.folderItems.length - 1 ? (
            <Button
              onClick={() => this.orderReportsUp(record)}
              className="table-action-btn-sort"
            >
              <FontAwesomeIcon icon={['fa', 'sort-up']} />
            </Button>
          ) : null}

          {this.props.folderItemStore.folderItems.length > 1 &&
          index != 0 &&
          index != this.props.folderItemStore.folderItems.length - 1 ? (
            <span className="sorters-btn-wrapper">
              <Button
                onClick={() => this.orderReportsUp(record)}
                className="table-action-btn-sort"
              >
                <FontAwesomeIcon icon={['fa', 'sort-up']} />
              </Button>

              <Button
                onClick={() => this.orderReportsDown(record)}
                className="table-action-btn-sort"
              >
                <FontAwesomeIcon icon={['fa', 'sort-down']} />
              </Button>
            </span>
          ) : null}
        </span>
      ),
    },
    {
      title: 'PowerBI Berichts Name',
      dataIndex: 'name',
      width: '25%',
    },
    {
      title: 'Anzeige-Name',
      dataIndex: 'displayName',
      width: '25%',
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          onChange={(e) => this.onInputChange(e, 'displayName', record)}
          onBlur={() => this.onSaveReport(record)}
          onPressEnter={() => this.onSaveReport(record)}
        />
      ),
    },
    {
      title: 'Aktiv',
      dataIndex: 'isActive',
      align: 'center',
      render: (text, record, index) => (
        <Checkbox
          checked={text}
          onChange={(e) => this.onCheckboxChange(e, 'isActive', record)}
        />
      ),
    },
    {
      title: 'Für Alle Sichtbar',
      dataIndex: 'showEveryone',
      align: 'center',
      render: (text) =>
        text == true ? <Icon type="check" /> : <Icon type="close" />,
    },
    {
      title: 'Aktion',
      dataIndex: 'action',
      className: 'table-header-action',
      render: (text, record) => (
        <span>
          <Button
            onClick={() => this.showPermissionModalForReport(record)}
            className="table-action-btn"
          >
            <FontAwesomeIcon icon={['fa', 'eye']} />
          </Button>
          <span className="table-icon-seperator">|</span>
          <Button
            onClick={() => this.showDeleteFolderItemModal(record)}
            className="table-action-btn-del"
          >
            <FontAwesomeIcon icon={['fa', 'trash']} />
          </Button>
        </span>
      ),
    },
  ];

  onClickFolderName = (event) => {
    event.stopPropagation();
  };

  onInputChange = (e, key, record) => {
    record[key] = e.target.value;
  };

  //Für Reports is Active
  onCheckboxChange = (e, key, record) => {
    record[key] = !record[key];

    this.onSaveReport(record);
  };

  showReportForFolder = (folder, event) => {
    event.stopPropagation();
    this.props.folderItemStore.fetchAllFolderItemsForFolder(folder.id);
    this.props.folderItemStore.folder = folder;
    this.props.folderStore.folder = folder;
    this.setState({ showReportForFolder: true });
  };

  showDeleteFolderModal = (folder, event) => {
    event.stopPropagation();
    this.setState({ showDeleteFolderModal: true });
    this.props.folderItemStore.fetchAllFolderItemsForFolder(folder.id);
    this.props.folderItemStore.folder = folder;
  };

  showDeleteFolderItemModal = (folderItem) => {
    this.setState({ showDeleteFolderItemModal: true });
    this.props.folderItemStore.folderItem = folderItem;
  };

  deleteFolder = (folder) => {
    this.props.folderStore
      .deleteFolder(folder.id)
      .then((resp) => {
        if (resp.status == 200) {
          openNotificationWithIcon(
            'success',
            'Erfolgreich!',
            'Der Ordner wurde gelöscht!'
          );
          this.handleCancel();
          this.props.folderStore.fetchAllFolders();
          this.props.sidebarStore.fetchSidebarElements();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Der Ordner konnte nicht gelöscht werden!'
        );
      });
  };

  deleteFolderItem = (folderItem) => {
    this.props.folderItemStore
      .getFolderItem({
        id: undefined,
        folderId: this.props.folderItemStore.folder.id,
        powerBiReportId: folderItem.id,
      })
      .then((resp) => {
        if (resp.status == 200) {
          this.props.folderItemStore
            .deleteFolderItem(resp.data.id)
            .then((resp) => {
              if (resp.status == 200) {
                openNotificationWithIcon(
                  'success',
                  'Erfolgreich!',
                  'Der Bericht wurde erfolgreich aus dem Ordner gelöscht!'
                );
                this.handleCancel();
                this.props.folderItemStore.fetchAllFolderItemsForFolder(
                  this.props.folderItemStore.folder.id
                );
                this.props.sidebarStore.fetchSidebarElements();
              }
            })
            .catch((err) => {
              openNotificationWithIcon(
                'error',
                'Fehler!',
                'Der Bericht konnte nicht gelöscht werden!'
              );
            });
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Der Bericht konnte nicht gelöscht werden!'
        );
      });
  };

  orderFoldersUp = (folder, event) => {
    event.stopPropagation();

    this.props.folderStore
      .updateFolderOrderUp(folder.id)
      .then((resp) => {
        if (resp.status == 200) {
          this.props.folderStore.fetchAllFolders();
          this.props.sidebarStore.fetchSidebarElements();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht getätig werden!'
        );
      });
  };

  orderFoldersDown = (folder, event) => {
    event.stopPropagation();

    this.props.folderStore
      .updateFolderOrderDown(folder.id)
      .then((resp) => {
        if (resp.status == 200) {
          this.props.folderStore.fetchAllFolders();
          this.props.sidebarStore.fetchSidebarElements();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht getätig werden!'
        );
      });
  };

  orderReportsDown = (report) => {
    this.props.folderItemStore
      .getFolderItem({
        id: undefined,
        folderId: this.props.folderItemStore.folder.id,
        powerBiReportId: report.id,
      })
      .then((resp) => {
        this.props.folderItemStore
          .updateFolderItemOrderDown(resp.data.id)
          .then((resp2) => {
            if (resp2.status == 200) {
              this.props.folderItemStore.fetchAllFolderItemsForFolder(
                this.props.folderItemStore.folder.id
              );
              this.props.sidebarStore.fetchSidebarElements();
            }
          });
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht getätig werden!'
        );
      });
  };

  orderReportsUp = (report) => {
    this.props.folderItemStore
      .getFolderItem({
        id: undefined,
        folderId: this.props.folderItemStore.folder.id,
        powerBiReportId: report.id,
      })
      .then((resp) => {
        this.props.folderItemStore
          .updateFolderItemOrderUp(resp.data.id)
          .then((resp2) => {
            if (resp2.status == 200) {
              this.props.folderItemStore.fetchAllFolderItemsForFolder(
                this.props.folderItemStore.folder.id
              );
              this.props.sidebarStore.fetchSidebarElements();
            }
          });
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht getätig werden!'
        );
      });
  };

  onSavePermissionsForReport = (folderItem, userIds, mandantIds) => {
    if (folderItem.showEveryone) {
      userIds = [];
      mandantIds = [];
    }

    this.props.folderItemStore
      .grantPermission({ folderItemId: folderItem.id, userIds: userIds })
      .then((resp) => {
        if (resp.status == 200) {
          this.props.folderItemStore
            .updateFolderItem(folderItem)
            .then((resp) => {
              if (resp.status == 200) {
                this.props.mandantStore.PostPermissionMandantForFolderItem({
                  folderItemId: folderItem.id,
                  mandantIds: mandantIds,
                });

                openNotificationWithIcon(
                  'success',
                  'Erfolgreich!',
                  'Änderung wurde gespeichert!'
                );

                this.handleCancel();
                this.props.folderItemStore.fetchAllFolderItemsForFolder(
                  this.props.folderItemStore.folder.id
                );
                this.props.sidebarStore.fetchSidebarElements();
              }
            })
            .catch((err) => {
              openNotificationWithIcon(
                'error',
                'Fehler!',
                'Änderung konnte nicht gespeichert werden!'
              );
            });
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderungen konnten nicht gespeichert werden!'
        );
      });
  };

  onSavePermissionsForFolder = (folder, userIds, mandantIds) => {
    if (folder.showEveryone) {
      userIds = [];
      mandantIds = [];
    }

    this.props.folderStore
      .grantPermission({ folderId: folder.id, userIds: userIds })
      .then((resp) => {
        if (resp.status == 200) {
          this.props.folderStore
            .updateFolder(folder)
            .then((resp) => {
              if (resp.status == 200) {
                this.props.mandantStore
                  .PostPermissionMandantForFolder({
                    folderId: folder.id,
                    mandantIds: mandantIds,
                  })
                  .then((resp) => {
                    openNotificationWithIcon(
                      'success',
                      'Erfolgreich!',
                      'Änderung wurde gespeichert!'
                    );
                    this.handleCancel();
                    this.props.folderStore.fetchAllFolders();
                    this.props.sidebarStore.fetchSidebarElements();
                  })
                  .catch((err) => {
                    openNotificationWithIcon(
                      'error',
                      'Fehler!',
                      'Änderung konnte nicht gespeichert werden!'
                    );
                  });
              }
            })
            .catch((err) => {
              openNotificationWithIcon(
                'error',
                'Fehler!',
                'Änderung konnte nicht gespeichert werden!'
              );
            });
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht gespeichert werden!'
        );
      });
  };

  createFolder = (folder, userIds, mandantIds) => {
    if (folder.ShowEveryone) {
      userIds = [];
      mandantIds = [];
    }

    this.props.folderStore
      .createFolder(folder)
      .then((resp) => {
        if (resp.status == 200) {
          this.props.folderStore
            .grantPermission({ folderId: resp.data.id, userIds: userIds })
            .then((resp2) => {
              if (resp2.status == 200) {
                this.props.mandantStore.PostPermissionMandantForFolder({
                  folderId: resp.data.id,
                  mandantIds: mandantIds,
                });

                openNotificationWithIcon(
                  'success',
                  'Erfolgreich!',
                  'Ordner wurde erfolgreich erstellt!'
                );
                this.handleCancel();
                this.props.folderStore.fetchAllFolders();
                this.props.sidebarStore.fetchSidebarElements();
              }
            });
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Ordner konnte nicht erstellt werden!'
        );
      });
  };

  onSaveReport = (report) => {
    this.props.powerBiReportStore
      .updateReport(report)
      .then((resp) => {
        if (resp.status == 200) {
          openNotificationWithIcon(
            'success',
            'Erfolgreich!',
            'Änderung wurde gespeichert!'
          );
          this.handleCancel();
          this.props.folderItemStore.fetchAllFolderItemsForFolder(
            this.props.folderItemStore.folder.id
          );
          this.props.sidebarStore.fetchSidebarElements();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht gespeichert werden!'
        );
      });
  };

  onSaveFolder = (folder) => {
    this.props.folderStore
      .updateFolder(folder)
      .then((resp) => {
        if (resp.status == 200) {
          openNotificationWithIcon(
            'success',
            'Erfolgreich!',
            'Änderung wurde gespeichert!'
          );
          this.handleCancel();
          this.props.folderStore.fetchAllFolders();
          this.props.sidebarStore.fetchSidebarElements();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht gespeichert werden!'
        );
      });
  };

  deleteReport = (record) => {
    this.props.powerBiStore
      .deleteReportFromCloud(record.reportId)
      .then((resp) => {
        openNotificationWithIcon(
          'success',
          'Erfolgreich!',
          'Report wurde erfolgreich gelöscht!'
        );
        this.props.powerBiReportStore.fetchAllReports();
      });
  };

  handleCancel = () => {
    this.setState({ showPermissionModalForReport: false });
    this.setState({ showPermissionModalForFolder: false });
    this.setState({ showAddFolderModal: false });
    this.setState({ showAddReportModal: false });
    this.setState({ showDeleteFolderModal: false });
    this.setState({ showDeleteFolderItemModal: false });

    this.props.folderStore.modalOpen = false;
    this.props.folderItemStore.modalOpen = false;
  };

  showPermissionModalForReport = (report) => {
    this.props.folderItemStore.report = report;

    this.props.folderItemStore
      .getFolderItem({
        id: undefined,
        folderId: this.props.folderItemStore.folder.id,
        powerBiReportId: report.id,
      })
      .then((resp) => {
        if (resp.status == 200) {
          this.props.folderItemStore.folderItem = resp.data;
          this.props.folderItemStore.modalOpen = true;

          this.props.mandantStore
            .fetchMandantsForFolderItem(resp.data.id)
            .then((resp) => {
              if (resp.status == 200) {
                this.setState({ showPermissionModalForReport: true });
              }
            });
        }
      });
  };

  showPermissionModalForFolder = (folder, event) => {
    event.stopPropagation();
    this.props.mandantStore.fetchMandantsForFolder(folder.id).then((resp) => {
      if (resp.status == 200) {
        this.props.folderStore.folder = folder;
        this.props.folderStore.modalOpen = true;
        this.setState({ showPermissionModalForFolder: true });
      }
    });
  };

  componentDidMount() {
    this.props.powerBiReportStore.fetchAllReports();
    this.props.mandantStore.fetchAllMandants();
    this.props.folderStore.fetchAllFolders();
    this.props.userStore.fetchUsers();
  }

  onClickAddFolder = () => {
    this.setState({ showAddFolderModal: true });
  };

  onClickAddReport = () => {
    this.setState({ showAddReportModal: true });
  };

  //Adding a report to a folder (folderitem)
  addReport = (folderItem, userIds, report, mandantIds) => {
    if (folderItem.ShowEveryone) {
      userIds = [];
      mandantIds = [];
    }

    this.props.folderItemStore.createFolderItem(folderItem).then((resp) => {
      if (resp.status == 200) {
        this.grantPermissionForFolderItem(resp.data.id, userIds);
        this.props.mandantStore
          .PostPermissionMandantForFolderItem({
            folderItemId: resp.data.id,
            mandantIds: mandantIds,
          })
          .catch((err) => console.log(err));

        this.props.powerBiReportStore
          .updateReport(report)
          .then((resp) => {
            if (resp.status == 200) {
              openNotificationWithIcon(
                'success',
                'Erfolgreich!',
                'Der Bericht wurde erfolgreich erstellt'
              );
              this.handleCancel();
              this.props.folderItemStore.fetchAllFolderItemsForFolder(
                this.props.folderItemStore.folder.id
              );
              this.props.sidebarStore.fetchSidebarElements();
            }
          })
          .catch((err) => {
            openNotificationWithIcon(
              'error',
              'Fehler!',
              'Der Bericht konnte nicht erstellt werden!'
            );
          });
      }
    });
  };

  grantPermissionForFolderItem = (folderItemId, userIds) => {
    this.props.folderItemStore
      .grantPermission({ folderItemId: folderItemId, userIds: userIds })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht gespeichert werden!'
        );
      });
  };

  onBackToFolders = () => {
    this.setState({ showReportForFolder: false });
  };

  render() {
    let folders = this.props.folderStore.folders
      ? toJS(this.props.folderStore.folders)
      : [];

    let folderItems = this.props.folderItemStore.folderItems
      ? toJS(this.props.folderItemStore.folderItems)
      : [];

    return (
      <React.Fragment>
        <h2>Power-BI-Administration</h2>
        <Card>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Ordnerübsicht" key="1">
              {!this.state.showReportForFolder ? (
                <span>
                  <Table
                    rowKey="id"
                    columns={this.folderColumns}
                    dataSource={folders}
                    scroll={{ x: 950 }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          event.persist();
                          this.showReportForFolder(record, event);
                        },
                      };
                    }}
                    footer={() => (
                      <Button onClick={this.onClickAddFolder}>
                        Neuen Ordner anlegen
                      </Button>
                    )}
                  />
                </span>
              ) : (
                <span>
                  <Row>
                    <a className="back-btn" onClick={this.onBackToFolders}><FontAwesomeIcon icon={['fa', 'long-arrow-alt-left']} /> Zurück</a>
                  </Row>

                  <Table
                    rowKey="id"
                    columns={this.reportInFolderColumns}
                    dataSource={folderItems}
                    scroll={{ x: 950 }}
                    footer={() => (
                      <Button onClick={this.onClickAddReport}>
                        Neuen Bericht einfügen
                      </Button>
                    )}
                  />

                  <PowerBIReportPermissionModal
                    visible={this.state.showPermissionModalForReport}
                    handleCancel={this.handleCancel}
                    handleOk={this.onSavePermissionsForReport}
                    folderItemStore={this.props.folderItemStore}
                    powerBiReportStore={this.props.powerBiReportStore}
                    users={this.props.userStore.users}
                    mandantStore={this.props.mandantStore}
                  />
                </span>
              )}

              <AddFolderModal
                visible={this.state.showAddFolderModal}
                handleCancel={this.handleCancel}
                handleOk={this.createFolder}
                folderStore={this.props.folderStore}
                users={this.props.userStore.users}
                mandantStore={this.props.mandantStore}
              />

              <FolderPermissionModal
                visible={this.state.showPermissionModalForFolder}
                handleCancel={this.handleCancel}
                handleOk={this.onSavePermissionsForFolder}
                folderStore={this.props.folderStore}
                users={this.props.userStore.users}
                mandantStore={this.props.mandantStore}
              />

              <AddReportModal
                visible={this.state.showAddReportModal}
                handleCancel={this.handleCancel}
                handleOk={this.addReport}
                reports={this.props.powerBiReportStore.reports}
                folderStore={this.props.folderStore}
                folderItemStore={this.props.folderItemStore}
                users={this.props.userStore.users}
                mandantStore={this.props.mandantStore}
              />

              <DeleteFolderModal
                visible={this.state.showDeleteFolderModal}
                handleCancel={this.handleCancel}
                handleOk={this.deleteFolder}
                folder={this.props.folderItemStore.folder}
                folderItemStore={this.props.folderItemStore}
              />

              <DeleteFolderItemModal
                visible={this.state.showDeleteFolderItemModal}
                handleCancel={this.handleCancel}
                handleOk={this.deleteFolderItem}
                folderItem={this.props.folderItemStore.folderItem}
              />
            </TabPane>

            <TabPane tab="Berichtübericht" key="2">
              <ReportSummary
                powerBiReportStore={this.props.powerBiReportStore}
              />
            </TabPane>
          </Tabs>
        </Card>
      </React.Fragment>
    );
  }
}
