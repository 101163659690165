import axios from "axios";
import mobx, { observable } from "mobx";
import { isProduction, apiUrl } from "../globals";

const baseUrl = `${apiUrl}/mandant`;

class MandantStore {
  modalOpen = false;

  @observable
  mandants = [];

  @observable
  mandantsInFolder = [];

  @observable
  mandant = {};

  constructor() {
    if (!isProduction) {
      console.log("MandantStore created");
      window[`MandantStore`] = this;
    }
  }

  async createMandant(dto) {
    var resp = await axios.post(`${baseUrl}`, dto);
    return resp;
  }

  async grantPermission(dto) {
    var resp = await axios.post(`${baseUrl}/permission`, dto);
    return resp;
  }

  async fetchAllMandants() {
    var resp = await axios.get(`${baseUrl}/getmandants`);

    if (resp.status == 200) {
      resp.data.sort((a, b) => (a.order > b.order ? 1 : -1));
      this.mandants = resp.data;
    }
    return resp;
  }

  async fetchMandantsForFolder(id) {
    var resp = await axios.get(`${baseUrl}/getmandantsforfolder/${id}`);

    if (resp.status == 200) {
      this.mandantsInFolder = resp.data;
    }
    return resp;
  }

  async fetchMandantsForFolderItem(id) {
    var resp = await axios.get(`${baseUrl}/getmandantsforfolderitem/${id}`);

    if (resp.status == 200) {
      this.mandantsInFolder = resp.data;
    }
    return resp;
  }

  async updateMandant(dto) {
    var resp = await axios.put(`${baseUrl}/update`, dto);
    return resp;
  }

  async updateMandantOrderUp(id) {
    var resp = await axios.put(`${baseUrl}/updateorderup/${id}`);
    return resp;
  }

  async updateMandantOrderDown(id) {
    var resp = await axios.put(`${baseUrl}/updateorderdown/${id}`);
    return resp;
  }

  async deleteMandant(id) {
    var resp = await axios.delete(`${baseUrl}/delete/${id}`);
    return resp;
  }

  async PostPermissionMandantForFolder(dto) {
    var resp = await axios.post(`${baseUrl}/permissionmandantfolder`, dto);
    return resp;
  }

  async PostPermissionMandantForFolderItem(dto) {
    var resp = await axios.post(`${baseUrl}/permissionmandantfolderitem`, dto);
    return resp;
  }
}

export default new MandantStore();
