import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Form, Input, Checkbox } from "antd";
import _ from "lodash-es";

const FormItem = Form.Item;

@observer
export default class EditMandantModal extends Component {
  onFormChange = (e) => {
    this.setState({ error: false, invalidCreds: false });
  };
  handleCancel = () => {
    this.props.handleCancel();
  };

  handleOk = (entry) => {
    this.props.handleOk(entry);
  };
  render() {
    return (
      <WrappedForm
        handleOk={this.handleOk}
        handleCancel={this.handleCancel}
        showModal={this.props.showModal}
        onChange={this.onFormChange}
        entry={this.props.entry}
      />
    );
  }
}

@observer
class MandantForm extends React.Component {
  handleCancel = () => {
    this.props.handleCancel();
    this.props.form.resetFields();
  };

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.resetFields();

        let entry = this.props.entry;
        _.assign(entry, values);

        this.props.handleOk(entry);
      }
    });
  };

  render() {
    let entry = this.props.entry;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={"Mandant bearbeiten"}
        visible={this.props.showModal}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <Form>
          <FormItem label="Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Bitte geben Sie einen Namen ein!",
                },
              ],
              initialValue: entry.name,
            })(<Input placeholder="Mandantenname" />)}
          </FormItem>

          <FormItem label="Promourl">
            {getFieldDecorator("promo", {
              rules: [
                {
                  required: true,
                  message: "Bitte geben Sie eine Promourl ein!",
                },
              ],
              initialValue: entry.promo,
            })(<Input placeholder="Promourl" />)}
          </FormItem>

          <FormItem label="Aktiv">
            {getFieldDecorator("active", {
              initialValue: entry.active,
              valuePropName: "checked",
            })(<Checkbox />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}
const WrappedForm = Form.create({
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
})(MandantForm);
