import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Select, Button} from 'antd';
import { toJS } from 'mobx';
import { openNotificationWithIcon } from 'helpers/Notifications';
import RoleForReportModal from './RoleForReportModal';


@inject('powerBiReportStore', 'powerBiStore', 'sidebarStore', 'userStore', 'folderStore', 'folderItemStore')
@observer
export default class ReportSummary extends Component {
    state= {
      showRoleForReportModal: false,
    };

    componentDidMount = () => {
        this.props.powerBiReportStore.fetchAllReports();
    }

    saveRoles = () => {
      this.props.powerBiReportStore
      .addRoles({PowerBiReportId: this.props.powerBiReportStore.report.id, Roles: this.props.powerBiReportStore.report.roles})
      .then((resp) => {
        if (resp.status == 200) {
          openNotificationWithIcon(
            'success',
            'Erfolgreich!',
            'Änderung wurde gespeichert!'
          );

          this.handleCancel();
          this.props.powerBiReportStore.fetchAllReports();
          this.props.sidebarStore.fetchSidebarElements(); 
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Fehler!',
          'Änderung konnte nicht gespeichert werden!'
        );
      });
    }

    importReports = () => {
        this.props.powerBiStore.importReportsFromCloud().then((resp) => {
          openNotificationWithIcon(
            'success',
            'Erfolgreich!',
            'Berichte wurden erfolgreich importiert!'
          );
          this.props.powerBiReportStore.fetchAllReports();
        });
      };

    handleChange = (e, record) => {
        record.verbindungsmodus = e;

        this.props.powerBiReportStore
        .updateReport(record)
        .then((resp) => {
            if (resp.status == 200) {
                openNotificationWithIcon(
                    'success',
                    'Erfolgreich!',
                    'Änderung wurde gespeichert!'
                  );
                this.props.powerBiReportStore.fetchAllReports();
                this.props.sidebarStore.fetchSidebarElements();
            }
          }).catch((err) => {
            openNotificationWithIcon(
              'error',
              'Fehler!',
              'Änderung konnte nicht gespeichert werden!'
            );
          });
        this.props.powerBiReportStore.fetchAllReports();
    }

    handleCancel= () => {
      this.setState({showRoleForReportModal: false});
    }

    openRoleModal = (report) => {
      this.setState({showRoleForReportModal: true});
      this.props.powerBiReportStore.report = report;
    }

    reportInFolderColumns = [ 
        {
            title: 'Berichtname',
            key: 'berichtname',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: 'Verbindungsmodus',
            dataIndex: 'verbindungsmodus',
            render: (text, record, index) => (
                <Select
                    defaultValue={record.verbindungsmodus || "Modus auswählen..."}
                    style={{ width: 200 }}
                    onChange={(e) => this.handleChange(e, record)}>
                      <Select.Option key="import" value="Importmodus">Importmodus</Select.Option>
                      <Select.Option key="direct" value="DirectQuery">DirectQuery</Select.Option>
                </Select>),
            sorter: (a, b) => {
                if (a.verbindungsmodus == null) {
                    a.verbindungsmodus = "Modus auswählen...";
                }
                if (b.verbindungsmodus == null) {
                    b.verbindungsmodus = "Modus auswählen...";
                }

                return a.verbindungsmodus.localeCompare(b.verbindungsmodus);
            }
        },
        {
          title: 'Rollen',
          dataIndex: 'roles',
          render: (text, record, index) => (
            <Button className="blue" onClick={() => this.openRoleModal(record)}>Rollen bearbeiten</Button>
          ),
        }
      ];

    render() {
        let reports = this.props.powerBiReportStore.reports
        ? toJS(this.props.powerBiReportStore.reports)
        : [];

        return (
            <span>
                <Button onClick={() => this.importReports()} className="float-right">
                    Berichte von PowerBI importieren
                </Button> 

                <Table
                    rowKey="id"
                    className="ant-table-margin"
                    pagination={{pageSize: 15}}
                    columns={this.reportInFolderColumns}
                    dataSource={reports}
                    scroll={{ x: 950 }}/>

                <RoleForReportModal
                    visible={this.state.showRoleForReportModal}
                    handleCancel={this.handleCancel}
                    handleOk={this.saveRoles}
                    powerBiReportStore={this.props.powerBiReportStore}/>
            </span>
        );
    }
}
