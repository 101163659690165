import axios from "axios";
import mobx, { observable } from "mobx";
import { isProduction, apiUrl } from "../globals";
import { map, uniqBy } from "lodash-es";

const baseUrl = `${apiUrl}/`;

class SidebarStore {
  @observable
  elements = [];

  constructor() {
    if (!isProduction) {
      console.log("SidebarStore created");
      window[`SidebarStore`] = this;
    }
  }

  async fetchSidebarElements() {
    return await axios
      .get(`${baseUrl}folder/getfoldersforuser`)
      .then((resp) => {
        resp.data.sort((a, b) => (a.order > b.order ? 1 : -1));
        map(resp.data, (x) => {
          x.reports.sort((a, b) => (a.order > b.order ? 1 : -1));
        });

        this.elements = uniqBy(resp.data, (item) => item.id);
        return this.elements;
      });
  }
}

export default new SidebarStore();
