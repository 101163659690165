// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
import * as Msal from 'msal';
import axios from 'axios';
import { loginRequest, msalConfig, ISConfig } from './authConfig.js';
import PermissionStore from '../stores/PermissionStore';
import Oidc from 'oidc-client';
import AuthenticationStore from '../stores/AuthenticationStore.js';

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
export const myMSALObj = new Msal.UserAgentApplication(msalConfig);

//Manager from Identity Server Auth
export var mgr = new Oidc.UserManager(ISConfig);

//This has to be here in order for the redirect to work without an error.
myMSALObj.handleRedirectCallback(authRedirectCallBack);
function authRedirectCallBack(error, response) {}

//Auth User with Azure AD
export async function authUserAzureAD() {
  var accessToken;

  await myMSALObj
    .acquireTokenSilent(loginRequest)
    .then(function (accessTokenResponse) {
      accessToken = accessTokenResponse.accessToken;
    })
    .catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      myMSALObj.loginRedirect(loginRequest);
    });

  return accessToken;
}

export async function LoginUser(username, password) {
  var data = await AuthenticationStore.login(username, password);
  console.log(data);
}

export default myMSALObj;

/*
axios.interceptors.request.use(
  (config) => {
    var accessToken = PermissionStore.accessToken;
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      if (error.config.url.indexOf('api/powerbi/exportpdf') < 0) {
        PermissionStore.redirectToForbidden();
      }
    }
    return Promise.reject(error);
  }
);

*/