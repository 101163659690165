import { observable, computed } from "mobx";
import axios from "axios";

import { isProduction, apiUrl } from "../globals";

const url = `${apiUrl}/User`;

class UserStore {
  roles = ["Admin", "keine Rechte"];

  @observable
  users = [];

  @observable
  showRegisterModal = false;

  @observable
  remainingUsers = [];

  async fetchUsers() {
    var resp = await axios.get(`${url}/getusers`);
    return (this.users = resp.data);
  }

  async fetchRemainingUsers() {
    var resp = await axios.get(`${url}/getremainingusers`);
    this.remainingUsers = resp.data;
    return resp;
  }

  async registerUser(name, email, password) {
    var user = {
      displayName: name,
      email: email,
      password: password,
    };

    var resp = await axios.post(`${url}`, user);
    return resp.data;
  }

  async updateUser(id, dto) {
    var resp = await axios.put(`${url}/${id}`, dto);
    return resp.data;
  }

  async deleteUser(id) {
    var resp = await axios.delete(`${url}/${id}`);
    return resp.data;
  }

  async changePw(id, dto) {
    var resp = await axios.put(`${url}/newPw/${id}`, dto);
    return resp.data;
  }
}

export default new UserStore();
