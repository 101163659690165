import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { Table, Icon, Divider, Card, Input, Checkbox, Modal, Tabs } from "antd";
import { Button, List, Tag } from "antd";
import { openNotificationWithIcon } from "helpers/Notifications";
import AddMandantModal from "./AddMandantModal";
import UsersInMandantModal from "./UsersInMandantModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash-es";
import dayjs from "dayjs";
import EditMandantModal from "./EditMandantModal";

@inject("userStore", "mandantStore")
@observer
export default class MandantAdministartion extends Component {
  state = {
    showCreateModal: false,
    showEditModal: false,
    showEditEntryModal: false,
    showDeleteMandantModal: false,
    existsFlag: false,
    nameChangedFlag: false,
  };
  columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Promourl",
      dataIndex: "promo",
      width: '40%'
    },
    {
      title: "Anlagedatum",
      dataIndex: "created",
      render: (entry) => dayjs(entry).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Aktiv",
      dataIndex: "active",
      align: 'center',
      render: (entry) =>
        entry ? <Icon type="check" /> : <Icon type="close" />,
    },
    {
      title: "Aktion",
      dataIndex: "action",
      align: 'center',
      render: (text, record) => (
        <span>
          <Button
            onClick={() => this.showPermissionModal(record)}
            className="table-action-btn"
          >
            <FontAwesomeIcon icon={["fa", "eye"]} />
          </Button>
          <span className="table-icon-seperator">|</span>
          <Button
            onClick={() => this.showEditModal(record)}
            className="table-action-btn-edit"
          >
            <FontAwesomeIcon icon={["fa", "edit"]} />
          </Button>
          <span className="table-icon-seperator">|</span>
          <Button
            onClick={() => this.showDeleteMandantModal(record)}
            className="table-action-btn-del"
          >
            <FontAwesomeIcon icon={["fa", "trash"]} />
          </Button>
        </span>
      ),
    },
  ];

  componentDidMount() {
    this.props.mandantStore.fetchAllMandants();
    this.props.userStore.fetchRemainingUsers();
  }

  handleCancel = () => {
    this.setState({
      showEditModal: false,
      showCreateModal: false,
      showDeleteMandantModal: false,
      showEditEntryModal: false,
    });
    this.props.mandantStore.modalOpen = false;
    this.props.userStore.fetchRemainingUsers();
  };

  showCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  showPermissionModal = (mandant) => {
    this.props.mandantStore.mandant = mandant;
    let remainingUsers = toJS(this.props.userStore.remainingUsers);

    let usersInMandant = _.filter(
      this.props.userStore.users,
      (user) =>
        _.find(mandant.userInMandant, (item) => item.id == user.id) != undefined
    );

    this.props.userStore.remainingUsers = _.concat(
      remainingUsers,
      usersInMandant
    );

    this.setState({ showEditModal: true });
    this.props.mandantStore.modalOpen = true;
  };

  showEditModal = (mandant) => {
    this.props.mandantStore.mandant = mandant;
    this.setState({
      showEditEntryModal: true,
    });
  };

  showDeleteMandantModal = (mandant) => {
    this.props.mandantStore.mandant = mandant;
    this.setState({ showDeleteMandantModal: true });
  };

  onSavePermission = (mandant, userIds) => {
    this.props.mandantStore
      .grantPermission({ mandantId: mandant.id, userIds: userIds })
      .then((resp) => {
        if (resp.status == 200) {
          openNotificationWithIcon(
            "success",
            "Erfolgreich!",
            "Änderungen wurden erfolgreich gespeichert!"
          );
          this.handleCancel();
          this.props.mandantStore.fetchAllMandants();
          this.props.userStore.fetchRemainingUsers();
          this.props.userStore.fetchUsers();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Fehler!",
          "Die Änderungen konnten nicht gespeichert werden!"
        );
      });
  };

  createMandant = (mandant, userIds) => {
    this.props.mandantStore
      .createMandant(mandant)
      .then((resp) => {
        if (resp.status == 200) {
          this.props.mandantStore
            .grantPermission({ mandantId: resp.data.id, userIds: userIds })
            .then((resp) => {
              if (resp.status == 200) {
                openNotificationWithIcon(
                  "success",
                  "Erfolgreich!",
                  "Der Mandant wurde erfolgreich erstellt"
                );
                this.handleCancel();
                this.props.mandantStore.fetchAllMandants();
              }
            })
            .catch((err) => {
              openNotificationWithIcon(
                "error",
                "Fehler!",
                "Der Mandant konnte nicht erstellt werden!"
              );
            });
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Fehler!",
          "Der Mandant konnte nicht erstellt werden!"
        );
      });
  };

  deleteMandant = () => {
    this.props.mandantStore
      .deleteMandant(this.props.mandantStore.mandant.id)
      .then((resp) => {
        if (resp.status == 200) {
          openNotificationWithIcon(
            "success",
            "Erfolgreich!",
            "Mandant wurde erfolgreich gelöscht!"
          );
          this.handleCancel();
          this.props.mandantStore.fetchAllMandants();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Fehler!",
          "Der Mandant konnte nicht gelöscht werden!"
        );
      });
  };

  handleEditEntry = (entry) => {
    this.props.mandantStore
      .updateMandant(entry)
      .then((resp) => {
        openNotificationWithIcon(
          "success",
          "Erfolgreich!",
          "Änderungen gespeichert!"
        );
        this.handleCancel();
        this.props.mandantStore.fetchAllMandants();
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Fehler!",
          "Änderungen konnten nicht gespeichert werden!"
        );
      });
  };

  render() {
    let mandantStore = this.props.mandantStore;

    return (
      <React.Fragment>
        <Button onClick={() => this.showCreateModal()} className="float-right">
          Neue Mandanten anlegen
        </Button>

        <Table
          className="ant-table-margin"
          rowKey="id"
          columns={this.columns}
          dataSource={mandantStore.mandants.slice()}
          scroll={{ x: 850 }}
        />

        <AddMandantModal
          visible={this.state.showCreateModal}
          handleOk={this.createMandant}
          handleCancel={this.handleCancel}
          mandantStore={this.props.mandantStore}
          users={this.props.userStore.users}
        />

        <EditMandantModal
          showModal={this.state.showEditEntryModal}
          handleOk={this.handleEditEntry}
          handleCancel={this.handleCancel}
          entry={this.props.mandantStore.mandant}
        />

        <UsersInMandantModal
          visible={this.state.showEditModal}
          handleCancel={this.handleCancel}
          handleOk={this.onSavePermission}
          userStore={this.props.userStore}
        />

        <Modal
          onCancel={this.handleCancel}
          onOk={this.deleteMandant}
          okText={"Löschen"}
          cancelText={"Abbrechen"}
          cancelButtonProps={{ className: "white" }}
          okButtonProps={{ className: "red" }}
          visible={this.state.showDeleteMandantModal}
          title="Mandant löschen"
          destroyOnClose
        >
          <p>
            Wollen Sie diesen Mandanten wirklich{" "}
            <span style={{ color: "#ff5865" }}>entültig</span> löschen?
          </p>
        </Modal>
      </React.Fragment>
    );
  }
}
