import axios from "axios";
import mobx, { observable } from "mobx";
import { isProduction, apiUrl } from "../globals";

const baseUrl = `${apiUrl}/powerbireport`;

class PowerBiReportStore {
  @observable
  reports = [];

  @observable
  report = {};

  constructor() {
    if (!isProduction) {
      console.log("PowerBiReportStore created");
      window[`PowerBiReportStore`] = this;
    }
  }

  async fetchAllReports() {
    var resp = await axios.get(`${baseUrl}/getreports`);

    if (resp.status == 200) {
      this.reports = resp.data;
    }
    return resp;
  }

  async getReportById(id) {
    var resp = await axios.get(`${baseUrl}/getreport/${id}`);
    return resp;
  }

  async updateReport(dto) {
    var resp = await axios.put(`${baseUrl}/update`, dto);
    return resp;
  }

  async addRoles(dto) {
    var resp = await axios.post(`${baseUrl}/roles`, dto);
    return resp;
  }

  async deleteReport(id) {
    var resp = await axios.delete(`${baseUrl}/delete${id}`);
    return resp;
  }

  async PostPermission(dto) {
    var resp = await axios.post(`${baseUrl}/permission`, dto);
    return resp;
  }
}

export default new PowerBiReportStore();
