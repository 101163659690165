import React, { Component } from "react";
import { Provider, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Layout } from "antd";

import Headbar from "components/Headbar/Headbar";
import Sidebar from "components/Sidebar/Sidebar";
import Routes from "components/Routes/Routes";

import PermissionStore from "stores/PermissionStore"; //Exports singleton

import PowerBiStore from "stores/PowerBiStore";
import SidebarStore from "stores/SidebarStore";
import PowerBiReportStore from "stores/PowerBiReportStore";
import FilterStore from "stores/FilterStore";
import UserStore from "stores/UserStore";
import FolderStore from "stores/FolderStore";
import FolderItemStore from "stores/FolderItemStore";
import MandantStore from "stores/MandantStore";

import "styles/main.scss";

const { Content } = Layout;

@observer
class App extends Component {
  async componentDidMount() {
    PermissionStore.accessToken = this.props.token;
    PermissionStore.history = this.props.history;
    await PermissionStore.fetchUser();
  }

  render() {
    return (
      <Provider
        permissionStore={PermissionStore}
        sidebarStore={SidebarStore}
        powerBiStore={PowerBiStore}
        powerBiReportStore={PowerBiReportStore}
        filterStore={FilterStore}
        userStore={UserStore}
        folderStore={FolderStore}
        folderItemStore={FolderItemStore}
        mandantStore={MandantStore}
      >
        <Layout style={{ height: "100vh" }}>
          <Sidebar />
          <Layout>
            <Headbar />
            <Content>
              <Routes />
            </Content>
          </Layout>
        </Layout>
      </Provider>
    );
  }
}

export default withRouter(App);
