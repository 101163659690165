import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Home extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <h1>Willkommen im Reporting-Portal der f4i-Portal-Lösung!</h1>
      </>
    );
  }
}

export default withRouter(Home);
