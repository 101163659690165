import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Input, Row, Select, Checkbox, Form } from "antd";
import { map, indexOf } from "lodash-es";

const FormItem = Form.Item;

@inject("folderStore", "userStore", "mandantStore")
@observer
export default class AddFolderModal extends Component {
  onFormChange = (e) => {
    this.setState({ error: false, invalidCreds: false });
  };

  handleCancel = () => {
    this.props.handleCancel();
  };

  handleOk = (folder, userIds, mandantIds) => {
    this.props.handleOk(folder, userIds, mandantIds);
  };

  render() {
    return (
      <WrappedForm
        handleOk={this.handleOk}
        handleCancel={this.handleCancel}
        visible={this.props.visible}
        onChange={this.onFormChange}
        folderStore={this.props.folderStore}
        users={this.props.users}
        mandantStore={this.props.mandantStore}
      />
    );
  }
}

@observer
class FolderForm extends React.Component {
  state = {
    userIds: [],
    mandantIds: [],
    ShowEveryone: false,
  };

  handleChange = (value) => {
    this.setState({ userIds: value });
  };

  handleChangeMandantSelect = (value) => {
    this.setState({ mandantIds: value });
  };

  onCheckBoxChange = (e, key) => {
    this.setState({ ShowEveryone: e.target.checked });
  };

  handleCancel = () => {
    this.props.handleCancel();
    this.props.form.resetFields();
  };

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let folder = {
          Name: values.name,
          ShowEveryone: this.state.ShowEveryone,
        };

        this.props.form.resetFields();
        this.props.handleOk(folder, this.state.userIds, this.state.mandantIds);
        this.setState({ userIds: [] });
        this.setState({ mandantIds: [] });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    let mandantStore = this.props.mandantStore ? this.props.mandantStore : {};
    let mandants = mandantStore.mandants?.slice() || [];

    return (
      <Modal
        onCancel={this.props.handleCancel}
        onOk={this.handleOk}
        okText={"Speichern"}
        cancelText={"Abbrechen"}
        cancelButtonProps={{ className: "white" }}
        visible={this.props.visible}
        title="Neuen Ordner anlegen"
        destroyOnClose
      >
        <Form>
          <FormItem label="Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Bitte geben Sie einen Namen ein!",
                },
              ],
            })(<Input placeholder="Ordnername" />)}
          </FormItem>

          {/* <FormItem label="Personen hinzufügen">
            {getFieldDecorator("users")(
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="User auswählen"
                onChange={this.handleChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {map(this.props.users, (x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.upn || x.displayName}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem> */}

          <FormItem label="Mandanten hinzufügen">
            {getFieldDecorator("mandants")(
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Mandanten auswählen"
                onChange={this.handleChangeMandantSelect}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {map(mandants, (x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem label="Sichtbarkeit">
            <Checkbox onChange={this.onCheckBoxChange}>
              für alle sichtbar
            </Checkbox>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}
const WrappedForm = Form.create({
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
})(FolderForm);
