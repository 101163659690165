import axios from "axios";
import mobx, { observable } from "mobx";
import { isProduction, apiUrl } from "../globals";

const baseUrl = `${apiUrl}/folderitem`;

class FolderItemStore {
  modalOpen = false;

  @observable
  folderItems = [];

  @observable
  folderItem = {};

  @observable
  report = {};

  @observable
  folder = {};

  constructor() {
    if (!isProduction) {
      console.log("FolderItemStore created");
      window[`FolderItemStore`] = this;
    }
  }

  async createFolderItem(dto) {
    var resp = await axios.post(`${baseUrl}`, dto);
    return resp;
  }

  async grantPermission(dto) {
    var resp = await axios.post(`${baseUrl}/permission`, dto);
    return resp;
  }

  async getFolderItemPerId(id) {
    var resp = await axios.get(`${baseUrl}/getfolderitem/${id}`);

    if (resp.status == 200) {
      this.folderItem = resp.data;
    }
    return resp;
  }

  async fetchAllFolderItemsForFolder(id) {
    var resp = await axios.get(`${baseUrl}/getfolderitems/${id}`);

    if (resp.status == 200) {
      resp.data.sort((a, b) => a.order > b.order ? 1 : -1);
      this.folderItems = resp.data;
    }
    return resp;
  }

  async updateFolderItem(dto) {
    var resp = await axios.put(`${baseUrl}/update`, dto);
    return resp;
  }

  async updateFolderItemOrderUp(id) {
    var resp = await axios.put(`${baseUrl}/updateorderup/${id}`);
    return resp;
  }

  async updateFolderItemOrderDown(id) {
    var resp = await axios.put(`${baseUrl}/updateorderdown/${id}`);
    return resp;
  }

  async getFolderItem(dto) {
    var resp = await axios.post(`${baseUrl}/getfolderitemforid`, dto);
    return resp;
  }

  async deleteFolderItem(id) {
    var resp = await axios.delete(`${baseUrl}/delete/${id}`);
    return resp;
  }

  async PostPermission(dto) {
    var resp = await axios.post(`${baseUrl}/permission`, dto);
    return resp;
  }
}

export default new FolderItemStore();