import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import myMSALObj from '../../auth/auth.js';

import {
  Layout,
  Icon,
  Button,
  Breadcrumb,
  Row,
  Col,
  Dropdown,
  Menu,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { observer, inject } from 'mobx-react';
import icon_logo from '../../images/logo.png';

import { Logout } from '../api-authorization/Logout.js';

import {
  QueryParameterNames,
  LogoutActions,
  ApplicationPaths,
} from '../utils/api-authorization/ApiAuthorizationConstants';

import UserPasswordModal from '../Administration/UserPasswordModal'

const { Header } = Layout;

const breadcrumbNameMap = {
  powerbi: [{ name: 'PowerBi', link: 'powerbi' }],
};

@inject('permissionStore', 'sidebarStore')
@observer
class Headbar extends Component {
  state = {
    showPasswordModal: false,
  }

  routeTo(pathName) {
    this.props.history.push(pathName);
  }

  onCollapse = (collapsed) => {
    this.props.permissionStore.isSidebarCollapsed = collapsed;
  };

  onHomeClick = () => {
    this.props.history.push(`/`);
  };

  handleCancel = () => {
    this.setState({showPasswordModal: false});
  }

  render() {
    let collapsed = this.props.permissionStore.isSidebarCollapsed;
    const mediaQueryIsSmall = window.matchMedia('(max-width: 1200px)').matches;

    const { location } = this.props;
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    var map = breadcrumbNameMap[pathSnippets[0]];
    var items = map
      ? map.map((item) => (
          <Breadcrumb.Item
            key={item.link}
            onClick={() => this.routeTo(item.link)}
          >
            {item.name}
          </Breadcrumb.Item>
        ))
      : [];

    var menu = (
      <Menu className="dropdown-menu">
        {this.props.permissionStore.isAdmin ? (
          <Menu.Item className="admin-link">
            <a onClick={() => this.routeTo('useradministration')}>
              <FontAwesomeIcon icon={['fa', 'user-cog']} /> User-Administration
            </a>
          </Menu.Item>
        ) : null}
        {this.props.permissionStore.isAdmin ? (
          <Menu.Item className="admin-link">
            <a onClick={() => this.routeTo('powerbiadministration')}>
              <FontAwesomeIcon icon={['fa', 'cog']} /> PowerBI-Administration
            </a>
          </Menu.Item>
        ) : null}
        <Menu.Item className="admin-link">
          <a onClick={() => this.setState({showPasswordModal: true})}>
            <FontAwesomeIcon icon={['fa', 'key']} /> Passwort ändern
          </a>
        </Menu.Item>
        <Menu.Item className="logout">
          <a onClick={() => this.routeTo(`logout/${LogoutActions.Logout}`)}>
            <FontAwesomeIcon icon={['fa', 'sign-out-alt']} /> Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    let name = this.props.permissionStore.user.fullname;
    return (
      <Header className="header">
        <div className="icon-logo-mobile">
          <img
            src={icon_logo}
            alt="Fit4Internet Logo"
            onClick={this.onHomeClick}
          />
        </div>

        <div
          className={
            'toggle-menu-wrapper ' +
            (!this.props.permissionStore.isSidebarCollapsed ? ' mobile' : '')
          }
        >
          <Button
            onClick={() => {
              this.props.permissionStore.isSidebarCollapsed = !this.props
                .permissionStore.isSidebarCollapsed;
            }}
            id="toggle-menu-sidebar-btn"
          >
            {this.props.permissionStore.isSidebarCollapsed ? (
              <React.Fragment>
                <FontAwesomeIcon icon={['far', 'bars']} />
                <FontAwesomeIcon
                  className={'toggle-caret'}
                  icon={['fas', 'caret-right']}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FontAwesomeIcon
                  className={'toggle-caret'}
                  icon={['fas', 'caret-left']}
                />
                <FontAwesomeIcon icon={['far', 'bars']} />
              </React.Fragment>
            )}
          </Button>
        </div>

        <div className="logoutMenu">
          <Dropdown
            overlay={menu}
            placement="bottomCenter"
            overlayClassName="header-dropdown-menu"
          >
            <a className="ant-dropdown-link">
              {name} <FontAwesomeIcon icon={['fas', 'chevron-down']} />
            </a>
          </Dropdown>
        </div>

        <div className="logoutMenu-mobile">
          <Dropdown
            overlay={menu}
            placement="bottomCenter"
            trigger={['click']}
            overlayClassName="header-dropdown-menu"
          >
            <a className="ant-dropdown-link">
              <FontAwesomeIcon icon={['far', 'user']} />
            </a>
          </Dropdown>
        </div>

        <div
          className={
            this.props.permissionStore.isSidebarCollapsed
              ? 'display-none'
              : 'overlay'
          }
        />

      <UserPasswordModal
        visible={this.state.showPasswordModal}
        handleCancel={this.handleCancel}></UserPasswordModal>
      </Header>
    );
  }
}

export default withRouter(Headbar);
