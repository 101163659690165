import axios from "axios";
import mobx, { observable } from "mobx";
import { isProduction, apiUrl } from "../globals";

const baseUrl = `${apiUrl}/filter`;

class FilterStore {
  @observable
  filters = [];

  @observable
  filtersToSave = [];

  constructor() {
    if (!isProduction) {
      console.log("FilterStore created");
      window[`FilterStore`] = this;
    }
  }

  async getFilters(id) {
    var resp = await axios.get(`${baseUrl}/${id}`);
    this.filters = resp.data;
    return resp;
  }

  async saveFilters(id, filters) {
    return await axios.post(`${baseUrl}/${id}`);
  }

  async deleteFilters(id) {
    return await axios.delete(`${baseUrl}/${id}`);
  }
}

export default new FilterStore();
