import axios from "axios";
import mobx, { observable } from "mobx";
import { isProduction, apiUrl } from "../globals";

const baseUrl = `${apiUrl}/folder`;

class FolderStore {
  modalOpen = false;

  @observable
  folders = [];

  @observable
  folder = {};

  constructor() {
    if (!isProduction) {
      console.log("FolderStore created");
      window[`FolderStore`] = this;
    }
  }

  async createFolder(dto) {
    var resp = await axios.post(`${baseUrl}`, dto);
    return resp;
  }

  async grantPermission(dto) {
    var resp = await axios.post(`${baseUrl}/permission`, dto);
    return resp;
  }

  async fetchAllFolders() {
    var resp = await axios.get(`${baseUrl}/getfolders`);

    if (resp.status == 200) {
      resp.data.sort((a, b) => a.order > b.order ? 1 : -1);
      this.folders = resp.data;
    }
    return resp;
  }

  async updateFolder(dto) {
    var resp = await axios.put(`${baseUrl}/update`, dto);
    return resp;
  }

  async updateFolderOrderUp(id) {
    var resp = await axios.put(`${baseUrl}/updateorderup/${id}`);
    return resp;
  }

  async updateFolderOrderDown(id) {
    var resp = await axios.put(`${baseUrl}/updateorderdown/${id}`);
    return resp;
  }

  async deleteFolder(id) {
    var resp = await axios.delete(`${baseUrl}/delete/${id}`);
    return resp;
  }

  async PostPermission(dto) {
    var resp = await axios.post(`${baseUrl}/permission`, dto);
    return resp;
  }
}

export default new FolderStore();