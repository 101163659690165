import React, { Component } from 'react';

export default class Forbidden extends Component {
  componentDidMount() {}

  render() {
    return (
      <h1>
        Sie haben keinen Zugriff auf diese Applikation, bitte wenden Sie sich an einen Administrator
      </h1>
    );
  }
}
