import React, { Component } from 'react';
import { Modal } from 'antd';
import LoginForm from './UserRegisterForm';

export default class UserRegisterModal extends Component {  
    render() {

      return (
            <Modal
                title={this.props.user? "Benutzer bearbeiten" : "Neuen Benutzer anlegen"}
                visible={this.props.visibility}
                onCancel={this.props.handleCancel}
                destroyOnClose
                footer={[]}
            >
            
            <LoginForm
              user={this.props.user}
              roles={this.props.roles}></LoginForm>
          </Modal>
      );
    }
  }