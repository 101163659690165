import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Input, Row, Select, Checkbox, Form } from "antd";
import { map, indexOf } from "lodash-es";

const FormItem = Form.Item;

@inject("mandantStore", "userStore")
@observer
export default class AddMandantModal extends Component {
  onFormChange = (e) => {
    this.setState({ error: false, invalidCreds: false });
  };

  handleCancel = () => {
    this.props.handleCancel();
  };

  handleOk = (mandant, userIds) => {
    this.props.handleOk(mandant, userIds);
  };

  render() {
    return (
      <WrappedForm
        handleOk={this.handleOk}
        handleCancel={this.handleCancel}
        visible={this.props.visible}
        onChange={this.onFormChange}
        mandantStore={this.props.mandantStore}
        users={this.props.users}
      />
    );
  }
}

@observer
class MandantForm extends React.Component {
  state = {
    userIds: [],
    errMsg: "",
  };

  handleChange = (value) => {
    this.setState({ userIds: value });
  };

  handleCancel = () => {
    this.props.handleCancel();
    this.props.form.resetFields();
  };

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let mandant = values;

        let existsFlag = false;

        map(this.props.mandantStore.mandants, (x) => {
          x.name == mandant.name ? (existsFlag = true) : null;
        });

        if (!existsFlag) {
          this.props.form.resetFields();
          this.props.handleOk(mandant, this.state.userIds);
          this.setState({ errMsg: "" });
          this.setState({ userIds: [] });
        } else {
          this.setState({
            errMsg: "Der ausgewählte Mandantenname existiert bereits!",
          });
        }
      }
    });
  };

  handleCancel = () => {
    this.setState({ errMsg: "" });
    this.props.handleCancel();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        okText={"Speichern"}
        cancelText={"Abbrechen"}
        cancelButtonProps={{ className: "white" }}
        visible={this.props.visible}
        title="Neuen Mandanten anlegen"
        destroyOnClose
      >
        <Form>
          {this.state.errMsg.length > 0 ? (
            <Row style={{ color: "#ff5865" }}>{this.state.errMsg}</Row>
          ) : null}

          <FormItem label="Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Bitte geben Sie einen Namen ein!",
                },
              ],
            })(<Input placeholder="Mandantenname" />)}
          </FormItem>

          <FormItem label="Promourl">
            {getFieldDecorator("promo", {
              rules: [
                {
                  required: true,
                  message: "Bitte geben Sie eine Promourl ein!",
                },
              ],
            })(<Input placeholder="Promourl" />)}
          </FormItem>

          <FormItem label="Aktiv">
            {getFieldDecorator("active")(<Checkbox />)}
          </FormItem>

          {/* <FormItem label="Personen hinzufügen">
            {getFieldDecorator("users")(
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="User auswählen"
                onChange={this.handleChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {map(this.props.users, (x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.upn || x.displayName}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem> */}
        </Form>
      </Modal>
    );
  }
}
const WrappedForm = Form.create({
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
})(MandantForm);
