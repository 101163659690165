import React, { Component } from 'react';
import { Modal, Button, Row, Select } from 'antd';
import { map } from 'lodash-es';
import { toJS } from 'mobx';


export default class DeleteUserModal extends Component {
    handleOk = () => {
        this.props.handleOk(this.props.user);
    };

    render() {
        let user = this.props.user;

        return (
        <Modal
            onCancel={this.props.handleCancel}
            onOk={this.handleOk}
            okText={"Löschen"}
            cancelText={"Abbrechen"}
            cancelButtonProps={{className: "white"}}
            okButtonProps={{className: "red"}}
            visible={this.props.visibility}
            title="Benutzer löschen"
            destroyOnClose
            user={this.props.user}>

            <p>Wollen Sie den Benutzer <span className="highlighted-text">"{user ? user.displayName : ""}"</span> endgültig löschen?</p>
        </Modal>
        );
    }
}
