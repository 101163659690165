import React, { Component } from 'react';
import { Modal, Form, Button, Input } from 'antd';
import { map } from 'lodash-es';
import CryptoJS from 'crypto-js';
import { inject, observer } from 'mobx-react';
import { openNotificationWithIcon } from 'helpers/Notifications';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

export default class UserPasswordModal extends Component {
  handleOk = () => {
    openNotificationWithIcon(
      'success',
      'Erfolg',
      'Das Passwort wurde erfolgreich geändert.'
    );
    this.props.handleCancel();
  }

  render() {
    return (
      <WrappedForm
        handleOk={this.handleOk}
        handleCancel={this.props.handleCancel}
        visible={this.props.visible}></WrappedForm>
    );
  }
}

@inject('userStore', 'permissionStore')
@observer
class NewPasswordForm extends Component {
  componentDidMount = async() => {
    await this.props.permissionStore.fetchUser();
  }

  state = {
    error: {}
  };

  handleCancel = () => {
    this.setState({error: {}});
    this.props.handleCancel();
  }

  onFinish = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async(err, values) => {
      if (!err) {
        let oldPw = this.encryptPw(values.oldPw, '_v;8PtSTr]]?f!2D', ';Xb9AK?F*3-Ek9V3');
        let newPw = this.encryptPw(values.newPw, ',U!C4K6D!c?3v[t:', 'v!VH{,rU3qS4!6yX');
    
        var response = await this.props.userStore.changePw(
          this.props.permissionStore.user.id,
          {password: oldPw, newPassword: newPw}
        );
    
        //To display the response from the backend
        if (response.succeeded) {      
          this.props.form.resetFields();
          this.props.handleOk();
        } else {
          var errObj = {};
          map(response.errors, (error) => {
            if (error.code.indexOf('Pass') > -1) {
              if (!errObj.password) {
                errObj['password'] = [];
              }
              errObj['password'].push(<li key={error.code}>{error.description}</li>);
            } else if (error.code.indexOf('Pw') > -1) {
              if (!errObj.pwNotEqual) {
                errObj['pwNotEqual'] = [];
              }
              errObj['pwNotEqual'].push(<li key={error.code}>{error.description}</li>);
            }
          });
          this.setState({ error: errObj });
        }
      }
    });
  };

  encryptPw = (password, key, iv) => {
    var key = CryptoJS.enc.Utf8.parse(key);
    var iv = CryptoJS.enc.Utf8.parse(iv);

    var result = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return result.ciphertext.toString(CryptoJS.enc.Base64);
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPw')) {
      callback('Die eingegebenen Passwörter stimmen nicht überein!');
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let passError = this.state.error.password;
    let pwNotEqualError = this.state.error.pwNotEqual;

    return (
        <Modal
            onCancel={this.handleCancel}
            okText={"Speichern"}
            cancelText={"Abbrechen"}
            cancelButtonProps={{className: "white"}}
            visible={this.props.visible}
            title="Neus Passwort setzten"
            destroyOnClose
            footer={[]}>
            
            <Form {...layout} name="UserPassword" onSubmit={this.onFinish}>
            <Form.Item
                label="Altes Passwort"
                name="oldPw"
                hasFeedback={pwNotEqualError}
                validateStatus={pwNotEqualError ? 'error' : ''}
                help={pwNotEqualError}>
                {getFieldDecorator('oldPw', {
                  rules: [{ required: true, message: 'Bitte geben Sie Ihr altes Passwort an' }],
                })(
                <Input.Password placeholder="Altes Passwort"/>, )}
              </Form.Item>

              <Form.Item
                label="Neues Passwort"
                name="newPw"
                hasFeedback={passError}
                validateStatus={passError ? 'error' : ''}
                help={passError}>
                {getFieldDecorator('newPw', {
                  rules: [{ required: true, message: 'Bitte geben Sie Ihr neues Passwort an' }],
                })(
                <Input.Password placeholder="Neues Passwort"/>, )}
              </Form.Item>

              <Form.Item
                label="Neues Passwort bestätigen"
                name="newPwConfirm"
                hasFeedback={passError}
                validateStatus={passError ? 'error' : ''}
                help={passError}>
                {getFieldDecorator('newPwConfirm', {
                  rules: [
                    {
                      validator: this.compareToFirstPassword
                    },
                    {
                       required: true, message: 'Bitte bestätigen Sie Ihr neues Passwort'
                    }],
                })(
                <Input.Password placeholder="Neues Passwort bestätigen"/>, )}
              </Form.Item>

              <Button type="primary" htmlType="submit">
                Speichern
              </Button>
            </Form>
        </Modal>
    );
  }
}

const WrappedForm = Form.create({ name: 'NewPassword' })(NewPasswordForm);