import React, { Component } from 'react';
import { Modal, Button, Row, Select } from 'antd';
import { map } from 'lodash-es';
import { toJS } from 'mobx';


export default class DeleteFolderModal extends Component {
    componentDidMount = () => {

    }

    handleOk = () => {
        this.props.handleOk(this.props.folder);
    };

    render() {
        let folderItems = this.props.folderItemStore.folderItems
            ? toJS(this.props.folderItemStore.folderItems)
            : [];

        return (
        <Modal
            onCancel={this.props.handleCancel}
            onOk={this.handleOk}
            okText={"Löschen"}
            cancelText={"Abbrechen"}
            cancelButtonProps={{className: "white"}}
            okButtonProps={{className: "red"}}
            visible={this.props.visible}
            title="Ordner löschen"
            destroyOnClose
            folder={this.props.folder}
            folderItemStore={this.props.folderItemStore}>

            {folderItems ? (
                folderItems.length>0 ? (
                    <span>
                        <p>Wollen Sie den Ordner <span className="highlighted-text">"{this.props.folder.name}"</span> mit folgenden Berichten <span className="highlighted-text">endgültig</span> löschen?</p>
                        <p></p>
                        
                        {map(folderItems, (x) => (
                            <p key={x.id}>- <span className="highlighted-text">{x.name}</span></p>
                        ))}
                    </span>
                ) : (
                    <p>Wollen Sie den Order <span className="highlighted-text">"{this.props.folder.name}"</span>, welcher keine Berichte enthält, <span className="highlighted-text">endgültig</span> löschen?</p>
                )
            ): null}

        </Modal>
        );
    }
}
